/**
 * 3RD Plugins
 */
.btn-light{
    background-color: #fff;
    color: #000;
    &:hover{
        color: $theme-color;
    }
}
/* clearfix */
.owl-carousel .owl-wrapper:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}
/* display none until init */
.owl-carousel-play{
	margin-left: -$grid-gutter-width / 2;
	margin-right: -$grid-gutter-width / 2;
}
.owl-carousel{
	display: none;
	position: relative;
	width: 100%;
	-ms-touch-action: pan-y;
}
.owl-carousel .owl-wrapper{
	display: none;
	position: relative;
	-webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper-outer{
	overflow: hidden;
	position: relative;
	width: 100%;
    height: 100% ;
    direction: ltr !important;
}
.owl-carousel .owl-wrapper-outer.autoHeight{
	-webkit-transition: height 500ms ease-in-out;
	-moz-transition: height 500ms ease-in-out;
	-ms-transition: height 500ms ease-in-out;
	-o-transition: height 500ms ease-in-out;
	transition: height 500ms ease-in-out;
}
	
.owl-carousel .owl-item{
	float: left;
    padding: 0 $grid-gutter-width / 2;
}
.owl-controls .owl-page,
.owl-controls .owl-buttons div{
	cursor: pointer;
}
.owl-controls .owl-buttons div {
  position: absolute;
}
 
.owl-controls .owl-buttons .owl-prev{
  left: 10px;
  top: 50%; 
}

.owl-controls .owl-buttons .owl-next{
  right: 10px;
  top: 50%;
}

/* fix */
.owl-carousel  .owl-wrapper,
.owl-carousel  .owl-item{
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility:    hidden;
	-ms-backface-visibility:     hidden;
}
.owl-carousel.owl-theme {
	.owl-controls{
	    .owl-buttons {
	        margin: -26px 0 0 ;   
	        width: 100%;
	        right: 0;
	        position: absolute;
	        top: 50%;
	        div {
	            color: transparent;
	            z-index: 99;
	            background: transparent;
	            left: auto;
	            @include opacity(0);
	            @include transition(all 0.5s ease-in-out 0s);
	            @include box-shadow(none);
	            @include size(40px, 40px);
	            line-height: 40px;
	            font-size: 18px;
	            padding: 0;
	            margin: 0;
	            position: absolute;
	            background-color: rgba(#000, 0.4);
	            &:hover{
	                color: transparent;
	                @include box-shadow(none); 
	                background-color: $theme-color;
	                &:before{
	                    color: #fff;
	                }
	                &.owl-prev  {
		                left: 5px;
		            }
		            &.owl-next {
		                right: 5px;
		            }
	            }
	            &:before{
	                position: absolute;
	                top: 0;
	                left: 0;
	                right: 0;
	                margin: 0 auto;
	                font-family: FontAwesome;
	                color: #d7d7d7;
	                @include size(40px, 40px);
	                line-height: 40px;
	                text-align: center;
	            }
	            &.owl-prev  {
	                left: -50px;
	                @include transition(all 0.5s ease-in-out 0s);
	                &:before{
	                    content: "\f104";
	                }
	            }
	            &.owl-next {
	                right: -50px;
	                @include transition(all 0.5s ease-in-out 0s);
	                &:before{
	                    content: "\f105";
	                } 
	            }
	        }
	    }
	}
	&:hover{
		.owl-controls .owl-buttons {
	        div {
				@include opacity(1);
				@include transition(all 0.5s ease-in-out 0s);
		        &.owl-prev  {
		            left: 5px;
		            @include transition(all 0.5s ease-in-out 0s);
		        }
		        &.owl-next {
		            right: 5px;
		            @include transition(all 0.5s ease-in-out 0s);
		        }
			}
		}
    }
}
/*  Code */
.owl-carousel {
	margin: 0 0px; 
}
.owl-pagination {
	text-align: center;
	top: 0px;
	position: relative;
    margin-bottom: 30px;
}
.owl-controls .owl-page {
	display: inline-block;
	margin: 0px 6px;
}
.owl-controls .owl-page span {
	display: block;
    @include size(14px,4px);
	background:#cbcbcb;
/* 	box-shadow: inset 0 0 3px rgba(0,0,0,0.3);	 */
}
.owl-controls .owl-page.active{
    position: relative;
}
.owl-controls .owl-page.active span {
	background:$theme-color;
    @include size(14px,4px);
}
 
.owl-carousel-play {
    .carousel-control {
        opacity: 0;
        z-index: 1;
        right: 0;
        top: 50%;
        line-height: 32px;
        position: absolute;
        @include transition(all 0.5s ease-in-out 0s);
        background: white;
        &.left {
            left:-10px;
        }
        &.right {
            right: -10px;
            left: auto;
        }
    }
    &:hover{
        .carousel-control {
            opacity: 1;
            &.left {
                left: 15px;
            }
            &.right {
                right: 15px;
            }
        }        
    }
}
/**************/
// Timelife
.element-timelife{
	position:relative;
	.element-icon{
		height: 60px;
		margin-bottom:30px;	
		position: relative;
		background:$brand-primary;
		border-radius:50%;
		width:60px;
		font-size: 30px;
		line-height: 2em;
		text-align: center;
		&:before{
			background: #ececec none repeat scroll 0 0;
		    content: "";
		    height: 30px;
		    left: 30px;
		    position: absolute;
		    top: 60px;
		    transition: height 0.2s linear 0.3s;
		    width: 1px;
		}
	}
	.entry-timeline{
		padding-left: 20px;
		margin-left: 30px;
		position:relative;
		&:before{
			background:$brand-primary;
			width: 10px;
			height: 10px;
			border-radius:50%;
			content: "";
			position: absolute;
			left: -5px;
			z-index: 6
		}
		&:after{
			background: #ececec none repeat scroll 0 0;
		    content: "";
		    height: 110%;
		    left: 0px;
		    position: absolute;
		    top:0px;
		    transition: height 0.2s linear 0.3s;
		    width: 1px;	
		    z-index: 1
		}
		&:last-child:after{
			display: none;
		}
	}
}
/** List **/ 
ul {
	li {
		i {
			margin-right: 8px;
		}
	}
}
.lists.lists-light {
	li {
		&,
		a, 
		i {
			color: #fff;
		}
	}
}
/* feature box Opal */
.feature-box {
	.fbox-content{
		.description{
			line-height: 28px;
			margin-bottom: 40px;
		}
	}
	&.feature-box-nostyle,
	&.feature-box-v1 {
		position: relative;
		display: inline-block;
		.fbox-icon{
			position: absolute;
			content: "";
			top: 3px;
			left: 0;
			width: 20px;
			line-height: 20px;
			font-size: 18px;
			color: $theme-color;
		}
		.fbox-body{
			h4{
				font-size: 18px;
				padding-left: 25px;
				text-transform: capitalize;
				margin-top: 0;
			}
		}
	}
	&.feature-box-v2 {
		position: relative;
		text-align: right;
		.fbox-icon{
			position: absolute;
			content: "";
			top: 0;
			right: 0;
			width: 20px;
			font-size: 18px;
			line-height: 20px;
		}
		.fbox-body{
			h4{
				font-size: 18px;
				padding-right: 20px;
				text-transform: capitalize;
			}
		}
	}
	&.feature-box-v3,
	&.feature-box-v4 {
		position: relative;
		text-align: right;
		.fbox-icon{
			position: absolute;
			content: "";
			top: 0;
			right: 0;
			@include size(60px, 60px);
			line-height: 60px;
			background-color: $theme-color;
			color: #000;
			text-align: center;
			font-size: 30px;
		}

		.fbox-content{
			padding-right: 75px;
			h4{
				font-size: 24px;
				text-transform: capitalize;
			}
		}
	}
	&.feature-box-v1,
	&.feature-box-v3 {
		.fbox-content{
			h4{
				color: #fff;
			}
			.description{
				color: #cccccc;
			}
		}
	}

}

/****/

.testimonial-collection{
   overflow: hidden;
    .carousel-control{
        display: none;
    }
    .owl-carousel-play .owl-controls{
        display: block!important;
    }
    .owl-controls.clickable{
        position: relative;
        margin-bottom:  20px;
    }
    .testimonials-body{
        .testimonials-avatar img{
            width:90px; 
            height: 90px;
            overflow: hidden;
            margin-right: 15px; 
            @include border-radius(50%);
        }
        .testimonials-title{
            font-size: $font-size-base;
            font-weight: 600;
            color: #222;
            text-transform: uppercase;
            letter-spacing: 1px;
            margin-bottom: 15px;
        }
        .testimonials-description{
            font-family: $font-family-base;
        }
        .testimonials-quote {
            font-size: $font-size-base;
           
        }
        .name{ 
            text-transform: uppercase;
            font-size:$font-size-base;
            color: $theme-color; 
            font-family: $font-family-base;
            font-weight: 600;
            letter-spacing: 1px;
        }
        .job a{
            font-size: $font-size-base - 2;
            text-transform: uppercase;
            color: #999;
        }
       
    }
    .testimonials-left{
        .testimonials-avatar{ float: left; }
        .testimonials-quote {
            background-color: #999;
            margin-bottom: 20px;
            padding: 15px;
            position: relative;
            color: #666;
            &:after {
                bottom: -28px;
                color: #999;
                content: "\f0d7";
                font-family: "FontAwesome";
                font-size: 30px;
                margin-left: 40px;
                position: absolute;
                text-align: center;
                left: 0;
            }
        }
        .testimonials-body{
            display: table;
            margin-top:20px;
            margin-bottom: 20px;
            position: relative;
            display: block;
            overflow: hidden;
        }
    }

    .testimonials-v1 {
        padding: 20px;
        position: relative;
        text-align: center;
        
        .testimonials-quote {
            position: relative;
            margin-bottom: 20px;
            padding-bottom: 35px;
            font-size: 24px;
            font-weight: 300;
            font-style: italic;
            line-height: 38px;
            color: #000;
           
        }
        .testimonials-wrap{
            text-align: center;
        }
        .testimonials-profile{
        	font-family: $font-family-second;
            .name{
                text-transform: none;
                font-weight: 400;
                color: #000;
                font-family: $font-family-second;
                margin: 0;
            }
            .job a{
                text-transform: uppercase;
                font-size: 12px;
                color: #000;
            }
        }
        .testimonials-avatar{
            display:  inline-block;
            margin-right: 0;
        }
        .testimonials-body {
        }
    }
    .testimonials-v2{
        .testimonials-body{
            margin:20px 25px;
            position: relative;
        }
        .testimonials-avatar {
            position: absolute;
            top: 0;
            left: -14px;
            overflow: hidden;
        }
        .testimonials-description, .testimonials-meta, .testimonials-title{
            margin-left: 90px;
        }
    }
    .testimonials-v3 {
        text-align: center;
        color: #000;
        .testimonials-body{
            padding-left: 10%;
            padding-right: 10%;
            padding-bottom: 30px;
        }
        .testimonials-description{
        	line-height: 38px;
            font-size: 24px;
            margin-bottom: 30px;
            font-style: italic;
        }
        .testimonials-name{
            font-weight: 600;
            margin-bottom: 5px;
            font-size: 16px;
            text-transform: none;
            color: #000;
        }
        .job {
            font-size: 10px;
            line-height: 1;
            color: #000;
            a{
            	color: #000;
            	text-transform: none;
            }
        }
        .testimonials-avatar{
            margin-bottom: 20px;
        }
    }
    &.light-testimonial{
    	.testimonials-left{}
    	.testimonials-v1 {
    		.testimonials-quote {
    			color: #fff;
    		}
	        .testimonials-profile{
	            .name{
	                color: #fff;
	            }
	            .job a{
	                color: #fff;
	            }
	        }
	    }
    	.testimonials-v2{
    		color: #fff;
    	}
    	.testimonials-v3 {
	        color: #fff;
	        .testimonials-name{
	           
	            color: #fff;
	        }
	        .job {
	            color: #fff;
	            a{
	            	color: #fff;
	            }
	        }
	    }
    }
}

/** Posttype our team element **/
.team-collection {
	.owl-carousel-play{
		margin-left: -$grid-gutter-width / 2;
		margin-right: -$grid-gutter-width / 2;
	}
	.team-wrapper {
		text-align: center;
		margin-bottom: 20px;
		.team-header{
			position: relative;
			z-index: 3;
			.team-image{
				display: inline-block;
				&:before{
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					@include size(100%, 100%);
					background-color: rgba(#000, 0.4);
					z-index: 4;
					@include transition(all 0.35s ease 0s);
					@include  opacity(0);
					@include scale(0);
				}
			}
			.bo-social-icons{
				position: absolute;
				content: "";
				z-index: 5;
				@include size(100%, 90px);
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
				text-align: center;
				@include transition(all 0.35s ease 0s);
				@include  opacity(0);
				@include scale(0);
				> a{
					@include size(32px, 32px);
					line-height: 32px;
					color: #fff;
					text-align: center;
					font-size: 14px;
					background-color: rgba(#fff, 0.25);
					display: inline-block;
					&:hover{
						background-color: $theme-color;
					}
				}
			}
		}
		.team-name{
			font-size: 16px;
			margin-bottom: 0;
			margin-top: 20px;
		}
		.team-info{
			color: #fff;
			padding-bottom: 15px;
			i{
				margin-right: 10px;
			}
		}
		.team-job{
			font-size: 14px;
			color: #999;
			font-family: $font-family-second;
			font-weight: 400;
		}
		&:hover{
			.team-header{
				.team-image:before{
					@include transition(all 0.35s ease 0s);
					@include  opacity(1);
					@include scale(1);
				}
				.bo-social-icons{
					@include transition(all 0.35s ease 0s);
					@include  opacity(1);
					@include scale(1);
				}
			}
		}
	}
	&.style1{
		.team-wrapper {
			position: relative;
			.team-job {
				font-size: 14px;
				color: #fff;
				text-transform: uppercase;
				font-weight: 300;
			} 
			.team-name a{
				font-size: 18px;
				color: #fff;
				text-transform: uppercase;
				font-weight: 400;
				&:hover {
					color: $theme-color;
				}
			}
			.team-body{
				position: absolute;
				content: "";
				z-index: 6;
				@include size(100%, 32px);
				top: 15%;
				left: 0;
				right: 0;
				margin: auto;
				text-align: center;
				@include transition(all 0.35s ease 0s);
				@include  opacity(0);
				@include scale(0);
			}
			&:hover{
				.team-body{
					@include transition(all 0.35s ease 0s);
					@include  opacity(1);
					@include scale(1);
				}
			}
		}
	}
}

/** Posttype our team element **/
.pbr-team {
	.team-name{
		text-transform: none;
		margin-bottom: 0;
	}
	.team-job{
		font-style: italic;
		font-size: 14px;
	}

	.bo-social-icons{
		margin-bottom: 15px;
		> a{
			@include size(32px, 32px);
			line-height: 32px;
			color: #fff;
			text-align: center;
			font-size: 14px;
			background-color: rgba(#000, 0.25);
			display: inline-block;
			&:hover{
				background-color: $theme-color;
			}
		}
	}
	
		.team-header{
			overflow: hidden;
			img{
				@include transition(all 0.35s ease 0s);
				@include  opacity(1);
				@include scale(1);
			}
		}
		&:hover{
			.team-header{
				img{
					@include transition(all 0.35s ease 0s);
					@include  opacity(1.05);
					@include scale(1.05);
				}
			}
		}
	
	&.other-team {
		text-align: center;
		.team-info{
			display:  none;
		}
		&.v2{
			position: relative;

			.team-header{
				position: relative;
				z-index: 3;
				&:before{
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					@include size(100%, 100%);
					background-color: rgba(#000, 0.4);
					z-index: 4;
					@include transition(all 0.35s ease 0s);
					@include  opacity(0);
					@include scale(0);
				}
			}
			.bo-social-icons{
				position: absolute;
				bottom: 70px;
				content: "";
				height: 32px;
				width: 100%;
				left: 0;
				right:  0;
				z-index: 3;
				@include transition(all 0.35s ease 0s);
				@include  opacity(0);
				@include scale(0);
				> a{
					background-color: rgba(#fff, 0.45);
					&:hover{
						background-color: $theme-color;
					}
				}
			}
			&:hover{
				.team-header:before{
					@include transition(all 0.35s ease 0s);
					@include  opacity(1);
					@include scale(1);
				}
				.bo-social-icons{
					@include transition(all 0.35s ease 0s);
					@include  opacity(1);
					@include scale(1);
				}
			}
		}
	}
}


/* pricing */

.pricing{
    text-align: center;
    ul{
        padding-left:0;
        padding-right:0; 
        li{
            list-style: none;
        }
    }
    .no-suport{
    	color: #999!important;
    }
    &.pricing-v1{
     background: #FFFFFF; 
     text-align: left;
     .pricing-header > *{
        font-family: $font-family-second;
        text-align: right;
     }
     .pricing-header-inner{
     	background-color: $theme-color;
     	padding-right: 30px;
     	padding-left: 30px;
     	position: relative;
     	overflow: hidden;
     	&:before{
         	border-left: 285px solid transparent;
         	border-top: 149px solid $theme-color-second;
         	content: "";
         	top: 0;
         	right: 0;
         	position: absolute;
         }
         > *{
         	position: relative;
         	z-index: 3;
         }
     }
     .plan-title{ 
         font-size: 18px; 
         text-transform: uppercase; 
         font-weight: 400; 
         margin: 0; 
         padding: 20px 0 10px;  
         color: $white;  
     }
     .plan-figure{
         font-size: 60px; 
         font-weight: 700; 
         color: #fff; 
         
     }
     .plan-price{
         font-size: 16px;
         line-height: 1;
         padding: 5px 0 30px;
         font-family: $font-family-third;
         color: #fff; 
         p{
            margin: 0;
         }
     }
     .plan-currency{
         font-size: 30px;
         font-weight: 700;  
         color: #fff;  
         position: relative;
         top: -25px; 
     }
     .plain-info{
         ul{
         	margin: 0;
         }
         li{
             font-size: 14px; 
             color: #000;
             font-weight: 300;    
             padding: 10px 25px 10px 45px; 
             border-bottom: 1px solid $border-color;
             position: relative;
             &:last-chilid{
             	border-bottom: none;
             }
             &:before{
             	content: "\f05d";
             	font-family: FontAwesome;
             	font-size: 14px;
             	position: absolute;
             	top:  10px;
             	left: 25px;
             }
         }    
     }
     .pricing-footer{
         padding: 45px 0;
         background-color: #f7f7f7;
         text-align: center;
         position: relative;
         &:before{
         	border-right: 100px solid transparent;
         	border-bottom: 52px solid $theme-color-second;
         	content: "";
         	bottom: 0;
         	left: 0;
         	position: absolute;
         }
     }  
     &.pricing-highlight{
         border-color: $theme-color!important;
      }      
   }
    &.pricing-v2{
        background: #FFFFFF; 
        .plan-title{
            color: #000;
            font-size: 24px;
            text-transform: uppercase;
            font-weight: 300; 
            margin: 0;
            padding: 30px 0;  
        }
        .plan-price{
            background: $theme-color;
            margin: 0 -10px;    
            position: relative;
            height: 100px; 
            .plan-price-body{
                background: $theme-color;
                margin: 0 auto;
                border-radius: 50%;                                
                position: absolute;
                left: 0;
                top: -15px;
                right: 0;          
                color: #FFF;       
                @include square(130px);
            }
            .plan-figure{
                font-size: 48px;
                font-weight: 300; 
            }
            .plan-currency{
                font-size: 30px;   
                font-weight: 300;  
            }
        }
        .plain-info{
            padding: 20px; 
            li{
                color: #000; 
                line-height: 62px; 
                border-bottom: 1px dashed #C8C8C8;                
            }
        }
        .pricing-footer{
            padding: 0 20px 40px 20px; 
        }      
        &.pricing-highlight{
          .plan-title{
            background: $white;
            color: $black;
          }
          .plan-price-body{
            background: $theme-color;
            color: $white;
          }
          .btn-block{
            background: $theme-color; 
            border-color: $theme-color;
            &:hover, &:focus, &:active{
              background: none;
              color: $theme-color;
            }
          }
        }     
    }
    &.pricing-v3{
        background: #FFFFFF; 
        border:1px solid #DEDEDE;
        .plan-title{
            background: #261F2F;
            font-size: 24px; 
            text-transform: uppercase;
            font-weight: 300; 
            margin: 0; 
            padding: 30px 0;
            border: none; 
            span{
              color: $white;   
            }          
        }
        .plan-figure{
            font-size: 48px; 
            font-weight: 300; 
            color: #000; 
        }
        .plan-price{
            font-size: 16px;
            background: #332C3E;
            padding: 15px 0;
            > *{
              color: $white;
              line-height: 1;
            }
        }
        .plan-currency{
            font-size: 30px;
            font-weight: 300;     
        }
        .plain-info{
            li{
                font-size: 16px; 
                color: #000;
                font-weight: 300;                  
                background: #F8F8F8;  
                padding: 14px 0; 
                &:nth-child(2n+1){
                  background: $white;
                }
            }    
        }
        .pricing-footer{
            padding: 0px 20px 40px 20px;
        }  
        &.pricing-highlight{
          .plan-title{ 
            background: $theme-color;
            color: $white;
          }
          .plan-price{
            background: $theme-color;
            color: $white;
          }
          .btn-block{
            background: $theme-color; 
            border-color: $theme-color;
            &:hover, &:focus, &:active{
              background: none;
              color: $theme-color;
            }
          }
        }  
    }
    &.pricing-highlight{
        .plan-title{
            background: $theme-color; 
            color: #FFFFFF;            
        }
    }
}
.kc_row_inner {
	.kc-elm:nth-child(2){
		.pricing{
		    &.pricing-v1{
		    	.pricing-header-inner{
			     	&:before{
			         	border-top-color: #e40037;
			         }
			    }
		    
			    .pricing-footer{
			        &:before{
			         	border-bottom-color: #e40037;
			        }
			    } 
		    } 
		}
	}
	.kc-elm:nth-child(3){
		.pricing{
		    &.pricing-v1{
		    	.pricing-header-inner{
			     	&:before{
			         	border-top-color: #209112;
			         }
			    }
		    
			    .pricing-footer{
			        &:before{
			         	border-bottom-color: #209112;
			        }
			    } 
		    } 
		}
	}
	.kc-elm:nth-child(4){
		.pricing{
		    &.pricing-v1{
		    	.pricing-header-inner{
			     	&:before{
			         	border-top-color: #1d6270;
			         }
			    }
		    
			    .pricing-footer{
			        &:before{
			         	border-bottom-color: #1d6270;
			        }
			    } 
		    } 
		}
	}
	.kc-elm:nth-child(5){
		.pricing{
		    &.pricing-v1{
		    	.pricing-header-inner{
			     	&:before{
			         	border-top-color: #3ad4f3;
			         }
			    }
		    
			    .pricing-footer{
			        &:before{
			         	border-bottom-color: #3ad4f3;
			        }
			    } 
		    } 
		}
	}
}



/*$media (max-width: 767px) {*/
@media (min-width: $screen-xs-max) {
 /* effect-v1 */
.effect-v1{        
    position: relative;        
    img {
        transform-origin: right left;
        transition: transform 1s;
    }
    /* style for hover */
    &:hover{
        img {
            transform: rotate(5deg);
        }
    }
}
/* effect-v2*/
.effect-v2{    
    overflow: hidden;

    img {
        transition: transform 1s;
    }   
    /* style for hover */
    &:hover{       
        img {
            transform: translateX(10px) scale(1.2);
        }
        
    }
}


/* effect-v3*/
.effect-v3{
    overflow: hidden;
    position: relative;
    &:before,&:after {
        position: absolute;
        content: '';
        opacity: 0;        
        pointer-events: none;
        @include transition(opacity 0.35s, transform 0.35s);
    }
    &:before {
        top: 50px;
        right: 30px;
        bottom: 50px;
        left: 30px;
        z-index: 1;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        @include scale(0,1);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
    }
    &:after {
        top: 30px;
        right: 50px;
        bottom: 30px;
        left: 50px;
        border-right: 1px solid #fff;
        border-left: 1px solid #fff;
        -webkit-transform-origin: 100% 0;
        transform-origin: 100% 0;
        @include scale(1, 0);
    }
    a {
        width: 100%;
        height: 100%;
        display: inline-block;
    }
    img {
        @include transition (transform 0.35s);
        @include scale(1);
    }
    .banner-title{
        @include translate3d(0px, -20px, 0px);
        @include transition (transform 0.35s ease 0s);
    }
    .action{
        @include opacity(0);
        @include translate3d(0px, 40px, 0px);
        @include transition( opacity 0.35s ease 0s, transform 0.35s ease 0s);
    }

    /* style for hover */
    &:hover{
        &:before,
        &:after {
            @include opacity(1);
            @include scale(1);
        }
        &:before {

        }
        &:after {
            -webkit-transition-delay: 0.15s;
            transition-delay: 0.15s;
        }
        img {
            @include scale(1.1);
            -webkit-transition-delay: 0.15s;
            transition-delay: 0.15s;
        }
        .banner-title,.action{
                @include opacity(1);
                    @include translate3d(0px, 0px, 0px);
            }
    }
}

/* effect-v4*/
.effect-v4{
    background-color: #000;
    overflow: hidden;
    position: relative;
    &:before {
        border: 2px solid #ffffff;
        bottom: 20px;
        @include box-shadow(0 0 0 30px rgba(255, 255, 255, 0.2));
        content: "";
        left: 20px;
        opacity: 0;
        position: absolute;
        right: 20px;
        top: 20px;
        @include scale(1.4);
        @include transition( opacity 0.35s ease 0s, transform 0.35s ease 0s);
        z-index: 1;
    }
    img {
        @include opacity(1);
        @include transition (opacity 0.35s ease 0s);
    }
    .banner-title{
                @include scale(1);
                @include transition (transform 0.35s ease 0s);
            }
            .action{
                @include opacity(0);
                @include scale(1.2);
                @include transition( opacity 0.35s ease 0s, transform 0.35s ease 0s);
            }

    /* style for hover */
    &:hover{
        &:before {
            @include opacity(1);
            @include scale(1);
        }
        img {
            @include opacity(0.5);
        }
        .banner-title{
                @include scale(0.95);
            }
            .action{
                @include opacity(1);
                @include scale(1);
            }
    }
}


/* effect-v5*/
.effect-v5{
    background-color: #000;
    overflow: hidden;
    position: relative;
    img {
        @include opacity(1);
        @include transition (opacity 1s ease 0s, transform 1s ease 0s);
    }
    .action {
        transform: translate3d(-150%, 0px, 0px);
        @include transition (opacity 0.35s ease 0s, transform 0.35s ease 0s);
    }

    /* style for hover */
    &:hover{
        img {
            @include opacity(0.5);
            transform: scale3d(1.1, 1.1, 1);
        }
        .action {
            @include opacity(1);
            transform: scale3d(1.1, 1.1, 1);
            transform: translate3d(0px, 0px, 0px);
        }

    }
}
/* effect-v6*/
.effect-v6{
    background-color: #000;
    overflow: hidden;
    position: relative;

    img {
        @include opacity(1);
        @include transition (opacity 0.35s ease 0s);
    }
    .action {
        transform: translate3d(0px, -100px, 0px);
        transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
        opacity: 0;

    }

    /* style for hover */
    &:hover{
        img {
            @include opacity(0.5);
        }
        .action {
            opacity: 1;
            transform: translate3d(0px, 0px, 0px);
        }

    }
}

/* effect-v7*/
.effect-v7{
    overflow: hidden;
    position: relative;
    &:before {
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        @include transition(all 0.4s cubic-bezier(0.94, 0.85, 0.1, 0.62) 0s);
        z-index: 1;
        border: 200px double rgba(0, 0, 0, 0);
        @include opacity(1);
        visibility: visible;
    }

    /* style for hover */
    &:hover{
        &:before {
            @include opacity(0);
            border: 0 double rgba(255, 255, 255, 0.7);
            visibility: hidden;
        }
    }
}

/* effect-v8*/
.effect-v8{
    overflow: hidden;
    position: relative;
    &:before {
       border-bottom: 50px solid rgba(255, 255, 255, 0.4);
        border-top: 50px solid rgba(255, 255, 255, 0.4);
        content: "";
        height: 100%;
        left: 0;
        @include opacity(0);
        position: absolute;
        top: 0;
        transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
        -o-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
        -webkit-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
        -moz-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
        -ms-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
        @include transition (opacity 0.35s ease 0s, transform 0.35s ease 0s);
        transform-origin: 50% 50% 0;
        width: 100%;

    }

    /* style for hover */
    &:hover{
        &:before {
             @include opacity(1);
             transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 4, 1);
             -o-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 4, 1);
            -webkit-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 4, 1);
            -moz-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 4, 1);
            -ms-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 4, 1);
        }
    }
}

/* effect-v9*/
.effect-v9{
    overflow: hidden;
    position: relative;
    &:before {
        border: 50px solid rgba(0, 0, 0, 0);
        @include border-top-radius (50px);
        @include border-bottom-radius (50px);
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
        top: 0;
        content: "";
        @include opacity (1);
        @include size(100px, 100px);
        @include scale(7);
        @include transition (all 0.3s ease-in-out 0s);
        visibility: visible;
        z-index: 1;
    }

    /* style for hover */
    &:hover{
        &:before {
            border: 0 solid rgba(0, 0, 0, 0.7);
            @include opacity (0);
            visibility: hidden;
        }
    }
}

/* effect-v10*/
.effect-v10{
    overflow: hidden;
    position: relative;
    &:before {
        background-color: rgba(255, 255, 255, 0.2);
        display: block;
        @include size(100%, 100%);
        left: -100%;
        @include opacity (0);
        position: absolute;
        top: 0;
        @include transition (all 0.3s ease 0s);
        content: "";
        z-index: 1;
    }

    /* style for hover */
    &:hover{
        &:before {
          left: 0;
          @include opacity (1);
        }
    }
}

/* effect-v11*/
.effect-default{
        overflow: hidden;
      position: relative;
      display: block !important;
      &:before {
        background: rgba(255, 255, 255, 0.1) none repeat scroll 0 0;
        content: "";
        display: inline-block;
        height: 559px;
        position: absolute;
        right: 755px;
        top: 0;
        -webkit-transform: skew(-45deg);
        -moz-transform: skew(-45deg);
        -ms-transform: skew(-45deg);
        -o-transform: skew(-45deg);
        transform: skew(-45deg);
        @include transition(all 0.6s ease-in-out 0s);
        width: 600px;
      }

      // style for hover */
      &:hover{
        &:before {
            right: -900px;
        }
    }
}
}

.element-carousel-video .right, .element-carousel-video .left{
	background-color: $theme-color!important;
}

.element-carousel-video .play-video {
	&:hover{
		border-color: $theme-color!important;
	}
	i{
		color: $theme-color!important;
	}
}