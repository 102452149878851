/*
 * Global variables
 */
$image-theme-path                : '../images/' !default;
$image-theme-skin                : '../images/' !default;
// standard colors
$white		    : #FFF !default;
$black			: #000 !default;
$nocolor		: transparent !default;
$light-gray	: #CCC !default; 
$turquoise:   #473941 !default;


// color for default theme
$theme-color    : #f1ca31 !default; /* main color which will be used for all main block styles... */
$border-color	: #ededed !default;
$border-color-base:#e1e1e1 !default; 

$theme-color-second  			 : #f1ca31 !default;
$theme-color-default             : $theme-color !default;
$theme-color-hover               : $theme-color !default;
$color-other:                      #000!default;

// Theme Margin, Padding
// -------------------------
$theme-margin    : 20px !default;
$theme-padding   : 20px !default;
// fonts
$font-family-second :  $font-family-serif !default;
$font-family-third :  'Poppins', sans-serif !default;  
$theme-padding : 30px !default;

$font-icon: "FontAwesome"; 
/**
 * Import component variables
 */
@import "vars/form";
@import "vars/nav";
@import "vars/layout";
@import "vars/elements";
@import "vars/widget";
