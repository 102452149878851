/*
Theme Name: beatshop
Theme URI: https://wpopal.org/themes/beatshop/
Author: WPOPAL Team
Author URI: https://wordpress.org/
Description: In 2014, our default theme lets you create a responsive magazine website with a sleek, modern design. Feature your favorite homepage content in either a grid or a slider. Use the three widget areas to customize your website, and change your content's layout with a full-width page template and a contributor page to show off your authors. Creating a magazine website with WordPress has never been easier.
Version: 1.5
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Tags: black, green, white, light, dark, two-columns, three-columns, left-sidebar, right-sidebar, fixed-layout, responsive-layout, custom-background, custom-header, custom-menu, editor-style, featured-images, flexible-header, full-width-template, microformats, post-formats, rtl-language-support, sticky-post, theme-options, translation-ready, accessibility-ready
Text Domain: beatshop

This theme, like WordPress, is licensed under the GPL.
Use it to make something cool, have fun, and share what you've learned with others.
*/
@import "vars/vars";

@import "../../bootstrap";

@import "../../components/variables";
@import "../../components/mixins";
@import "../../components/base";
@import "../../components/template-layout";

@import "../../components/widget-layout";
@import "../../components/widgets";

@import "../../components/nav";
@import "../../components/page";

@import "../../components/post-formats";
@import "../../components/post-single";
@import "../../components/custom-posttype";

@import "../../components/elements";
@import "../../components/pagebuilder";
@import "../../components/responsive";
@import "../../components/typo";
@import "../../components/events";
@import "addition/style";
