.search-box-wrapper .wpo-search:hover{
	color: $theme-color-second;
}

.kc-title-wrap {
	h2.kc_title {
		text-transform: uppercase;
		padding-top: 5px;
		font-size: 44px;
		line-height: 52px;
        font-weight: 300;
  		&:before{
            content: "";
        }
    }
    h3.kc_title {
        text-transform: none;
        margin-top: 5px;
        font-size: 30px;
        font-weight: 300;
        letter-spacing: -1px;
    }
    h6.kc_title{
    	font-size: 18px;
    	color: $theme-color;
    	font-family: $headings-font-family-second;
    	font-weight: 300;
    	text-transform: none;
        font-style: italic;
        margin-bottom: 5px;
    }
}

.text-theme{
    color: $theme-color;
}
.text-default{
	color: $theme-color-second;
}

.opal-footer-profile{ 
    h3, .widgettitle {
        font-size: 18px;
        font-weight: 400;
        text-transform: none;
        color: #000;
    }
}
.kc_counter_box{ 
    .counterup{
        font-size: 48px;
        font-weight: 300!important;
    }
    h4{
        font-family: font-family-third;
        font-size: 14px;
        font-style: italic;
        font-weight: 300!important;
    }
}