#tribe-events{
	padding-left: $grid-gutter-width / 2;
	padding-right: $grid-gutter-width / 2;
}
.events-list {
	.tribe-events-loop {
	    max-width: 100%;
	}
}
.tribe-events-tooltip .tribe-events-event-thumb{
	 float: none;
    max-width: 100%;
    padding: 20px;
}
a.tribe-events-gcal, a.tribe-events-ical{
	margin-top:0;
}
#tribe-events-bar{
	@include box-shadow (0 0 0 1px $border-color inset) ;
	margin-bottom: 35px;
	@media screen and (min-width: 758px) {
		padding: 35px 0;
	}
	@media screen and (max-width: 758px) {
		padding: 15px 10px;
	}
	#tribe-bar-form{
		background: transparent;
		.first-column{
			padding-top: 15px;
			padding-bottom: 15px;
			border: 1px solid #E5E5E5;
			border-bottom: 0;
			input{
				margin-top: 10px;
				height: 30px;
				font-weight: 700;
				border-bottom: 1px solid #E5E5E5;
				font-size: 12px;
			}
			.tribe-bar-submit button{
				margin-top: 4px;
			}
		}
		.second-column{
			padding: 0; 
			#tribe-bar-views{
				left: 0;
				padding: 15px 0;
				width: 100%;
				text-align: center;
				background: #F2F2F2;
			}	
			.tribe-bar-views-list{ 
				padding: 0;
				> li{
					display: inline-block;
					padding: 5px 10px;
					border: 1px solid $black;
					background: none;
					margin: 0 5px;
					@include border-radius(3px);
					a{
						background: none;
						color: $black;
						font-weight: 300;
						text-transform: uppercase;
						font-size: 14px;
						span{
							line-height: 17px;
						}
					}
					&.tribe-bar-active, &:hover{
						background: $theme-color;
						border-color: $theme-color;
						a{
							color: $white;
						}	
					}
				}
			}
			.tribe-bar-views-inner{
				background: none;
				padding: 5px 0 45px;

			}
		}
		#tribe-bar-views {
			@media screen and (min-width: 758px) {
				padding: 12px 0;
				z-index: 3;
				width: 15%;
				left: 85%;
			}
			.tribe-bar-views-inner {
				padding: 0;
				background: none;
				label{
					display: none;
				}
				.tribe-bar-views-list {
					overflow: hidden;
					position: static;
					border-radius: 0;
					padding: 0;
					text-align: right;
					li.tribe-bar-views-option {
						display: inline-block;
						@media screen and (min-width: 758px) {
							margin-right: 20px;
						}
						@media screen and (max-width: 758px) {
							margin-right: 8px;
							margin-top: 15px;
						}
						a {
							background: none;
							color: #a5a5a5;
							padding: 0;
							@include square(16px);
							position: relative;
							text-align: center;
							line-height: 14px;
							span[class^="tribe-icon-"] {
								padding-left: 16px;
								text-indent: -9999px;
								display: none;
							}
							&:after {
								font-family: 'FontAwesome';
								font-size: 14px;
							}
							&:hover{
								color: $theme-color;
							}
						}
						&.tribe-bar-active {
							a {
								color: $theme-color;
							}
						}
						&.tribe-bar-views-option-month {
							a:after {
								content: "\f073";
							}
						}
						&.tribe-bar-views-option-list {
							a {
								line-height: 16px;
							}
							a:after {
								content: "\f039";
							}
						}
						&.tribe-bar-views-option-day {
							a:after {
								content: "\f272";
							}
						}
						&:first-child {
							margin-left: 0;
						}
					}
				}
			}
		}
		.tribe-bar-filters {
			.tribe-bar-filters-inner{
				.tribe-bar-date-filter,
				.tribe-bar-search-filter {
					input[type="text"] {
						padding: 0;
						border: none;
						line-height: 48px;
						margin: 0;
						border: 1px solid #fafafa;
						background-color: #fafafa;
						padding: 0 15px;
						font-weight: 400;
						font-size: 12px;
						&:focus {
							outline: none;
						}
					}
				}
				@media screen and (min-width: 758px) {
					.tribe-bar-date-filter{
						width: 33%;
					}
					.tribe-bar-search-filter{
						width: 50%;
					}
					.tribe-bar-submit{
						width: 17%;	
						margin-right: 0;
						margin-left: 0;
						margin-top: 20px;
					}
				}
			}
			@media screen and (max-width: 758px) {
				.tribe-bar-button {
					display: none;
				}
				.tribe-bar-submit{
					button{
						padding: 9px 50px;
						width: auto;
					}
				}
			}
		}
	}

	.tribe-bar-submit{
		button{
			background:$theme-color-second;
		    font-weight: 500;
		    @include border-radius(35px);
		    text-transform: uppercase;
		    line-height: $line-height-base;
		    color: #fff;
			padding: 9px 0;
			width: 100%;
			font-size: 12px;
			border: none;
			@include transition(all 0.35s ease 0s);
		    &:hover{
		   		background: darken($theme-color-second, 10%);
		    	@include box-shadow(0 8px 8px 0 rgba(0, 0, 0, 0.17));
		    	@include transition(all 0.35s ease 0s);
		    }
		}
	}
	@media(min-width: 758px){ 
		.tribe-bar-filters{
			position: absolute;
			width: 100%!important;
			right: auto!important;
			z-index: 2;
			.tribe-bar-filters-inner{
				position: relative;
				bottom: 0;
				padding: 20px;
				background-color: #fff;
				@include box-shadow(0 7px 15px 1px rgba(0, 0, 0, 0.3));
				@include opacity(0);
				@include transition(all 0.3s ease 0s);
				@include scale(0);
			}
			.tribe-bar-button{
				@include size( 42px, 42px);
				line-height: 42px;
				text-align: center;
				margin-left: 15px;
				cursor: pointer;
				&:hover{
					color: $theme-color;
				}

			}
			&.active{
				.tribe-bar-button{
					color: $theme-color;
				}
				.tribe-bar-filters-inner{
					@include opacity(1);
					bottom: -30px;
					@include transition(all 0.3s ease 0s);
					@include scale(1);
				}
			}
		}
	}
}

h2.tribe-events-page-title{
	text-transform: none!important;
	font-weight: 500;
	font-family: $font-family-second;
	@media screen and (min-width: 758px) {
		letter-spacing: -3px;
		font-size: 48px;
		top: -130px;
		margin: 0 !important;
		z-index: 0;
		position: absolute;
		left: 0;
		width: 100%;

	}
	@media screen and (max-width: 758px) {
		background-color: $theme-color;
		color: #fff;
		padding: 10px 20px;
	}
}

#tribe-bar-collapse-toggle{
	padding: 8px 10px;
	margin: 0;
	&:hover{
		color: $theme-color;
	}
}

.tribe-events-list {
	.tribe-events-inner{
		padding: 25px 0;
		@media screen and (max-width: 758px) {
			padding: 25px;
		}
	}
	.tribe-events-loop {
		.tribe-events-list-event-title{
			font-size: 20px !important;
			font-weight: 600;
			margin: 0 0 15px;
			letter-spacing: -1px;
			display: block;
		}
		.tribe-events-content {
			float: none;
			width: 100%;
			padding: 0;
			margin: 0;

		}
	}	
	.tribe-events-event-meta{
		font-size: 14px;
		color: $light-gray;
		padding: 35px 25px;
		margin: 0;
		font-weight: 300;
		@media screen and (max-width: $screen-sm-max) {
			background-color: #fafafa;
			border: none;
		}
		.event-time-wrapper{
			.time-details {
				margin-bottom: 0px;
				position: relative;
				padding-left: 25px;
				line-height: 1.8;
				&:before {
					font-family: 'FontAwesome';
					content: "\f017";
					color: #d3d3d3;
					min-width: 12px;
					text-align: center;
					position: absolute;
					top: 2px;
					left: 0;
				}
			}
		}
		.event-time-wrapper,
		.event-address-wrapper {
			margin-bottom: 15px;
		}
		.event-address-wrapper {
			position: relative;
			padding-left: 25px;
			line-height: 1.8;
			&:before {
				content: "\f041";
				min-width: 12px;
				text-align: center;
				font-family: 'FontAwesome';
				position: absolute;
				top: 0;
				left: 0;
				color: #d3d3d3;
			}
			.tribe-events-venue-details {
				font-style: normal;
				font-weight: 300;
				@media screen and (max-width: $screen-sm) {
					border-top: none;
					margin: 0;
					padding: 0;
				}	
				.author {
					margin-right: 0;
				}
				address.tribe-events-address {
					padding: 0;
					margin: 0 0 25px;
					font-style: normal;
					font-weight: 300;
					display: block;
				}
				a.tribe-events-gmap {
					display: inline-block;
					color: $theme-color-second;
				}
			}
		}
		.author {
			text-transform: none;
			font-size: 14px;
			line-height: 22px;
		}
	}
	.tribe-events-event-image{
		float: none;
		width: 100%;
		margin:0;
		@media screen and (max-width: $screen-sm) {
			img{
				max-height: 100%;
			}
		}
	}
	.type-tribe_events{
		.wpo-event-inner{
			position: relative;
			margin-bottom: 45px;
			.event-top {
				overflow: hidden;
			}
			.event-meta{
				width: 100%;
				float: left;
				font-family: $font-family-second;
				font-size: 12px;
				position: relative;
				padding-left: 130px;
				padding-right: 15px;
				.meta-left{
					position: absolute;
					@include size(105px, 100%);
					top: 0;
					left: 0;
					background: $theme-color;
					padding-top: 20px;
					> p{
						font-family: $font-family-second;
						text-align: center;
						color: $white;
						text-transform: uppercase;
						line-height: 1;
					}
					.day{
						font-size: 30px;
						position: relative;
						padding-bottom: 5px;
						margin-bottom: 5px;

						&:after{
							content: "";
							@include size(40px, 1px);
							position: absolute;
							background: $white;
							bottom: 0;
							left: 50%;
							margin-left: -20px;
							z-index: 1;
						}
					}
					.month{
						font-size: 12px;
					}
				}
				.meta-right{
					> span{
						float: left;
						margin-right: 15px;
						color: #aaaaaa;
						line-height: 1;
						i{
							margin-right: 5px;
						}
						&:last-child{
							margin-right: 0;
						}
					}
				}
			}
			&.style-2{
				margin: 0;
				position: relative;
				.event-date{
					position: absolute;
					top: 0;
					left: 0;
					margin-top: 0;
					@include size(70px, 70px);
					background: $theme-color;
					text-align: center;
					padding-top: 12px;
					>*{
						margin: 0;
						font-weight: 800;
						color: $white;
						text-transform: uppercase;
					}
					.day{
						font-size: 30px;
						line-height: 35px;
					}
					.month{
						font-size: 14px;
						line-height: 14px;
						font-weight: 400;
					}
				}
				.entry-title{
					a{
						font-size: 20px;
						font-weight: 800;
						color: $black!important;
						text-transform: uppercase;
						position: relative;
						display: block;
						padding-bottom: 25px;
						margin-bottom: 25px;
						line-height: 30px;
						@include transition(all 0.35s);
						&:hover{
							color: $theme-color!important;
						}
						&:after{
							content: "";
							@include size(70px, 2px);
							background: $theme-color;
							left: 0;
							bottom: 0;
							position: absolute;
						}
					}
				}
				.event-body{
					border: 1px solid #F5F5F5;
					background: $white;
					.left{
						padding: 0 150px 0 30px!important;
					}
					.right{
						background: $white;
						color: $theme-color;
						border: none;
						.event-cost{
							line-height: 1;
							margin-top: 30px;
							font-size: 30px;
							font-weight: 800;
							text-align: left;
							@media(max-width: $screen-sm-max){
								font-size: 20px;
							}
							.free{
								color: #CCCCCC;
							}
						}
					}
				}	
				.tribe-events-address, .time-details{
					color: #54575a;
				}
			}
		}
	}
}	

.events-list .tribe-events-loop,
.single-tribe_organizer .tribe-events-loop,
.single-tribe_venue .tribe-events-loop,
.tribe-events-day .tribe-events-loop{
	max-width: 100%!important;
}
.tribe-events-loop{
	.type-tribe_events{
		padding: 0px;
		margin-bottom: 30px!important;
		border: 1px solid $border-color!important;
		&:last-child{
			margin-bottom: 0;
		}
	}
	.tribe-events-list {
		position: relative;
		@media screen and (min-width: $screen-sm-max) {
			&:after {
				content: "";
				@include size(1px,100%);
				background-color: $border-color;
				position: absolute;
				top: 0;
				left: 76%;
			}
		}
	}
	.tribe-events-list-separator-month {
		text-transform: uppercase;
		font-family: $font-family-second;
		color: $theme-color;
		font-weight: 400;
		padding: 0;
		font-size: 24px;
		margin: 0 0 20px;
		&:before,
		&:after {
			content: none;
		}
	}
}
.tribe-events-list-separator-month + .type-tribe_events.tribe-events-first {
	padding-top: 0;
}
#tribe-events-footer{
	padding-top: 0 !important;
	margin: 0 0 25px !important;
	border-top: none!important;
	.tribe-events-sub-nav {
		a {
			text-transform: uppercase;
			font-size: 14px;
			font-weight: 400;
			color: $theme-color;
			&:hover {
				text-decoration: underline;
			}
		}

	}
}
#tribe-events .tribe-events-button, 
.tribe-events-button {
	background: $theme-color-second !important;
	font-size: 14px !important;
    font-weight: 500 !important;
    @include border-radius(35px!important);
    line-height: 1.875!important;
    padding: 9px 50px!important;
    height: auto!important;
    text-transform: uppercase;
    @include transition(all 0.35s ease 0s);
    &:hover{
   		background: darken($theme-color-second, 10%);
    	@include box-shadow(0 8px 8px 0 rgba(0, 0, 0, 0.17));
    	@include transition(all 0.35s ease 0s);
    }
}
#tribe-events-content.tribe-events-list {
	margin-bottom: 80px;
}
//--- Event detail-------------
.single-event{
	.social{
		margin-top: 50px;
		.addthis{
			margin:6px 0 0;
		}
	}
	.image-event{
		margin-bottom:$theme-margin;
	}
	strong{
		color: #000;
	}
}
.single-tribe_events {
	.tribe-events-event-meta{
		margin: 0 0 30px;
	}
	#tribe-events-content{
		margin-bottom: 0;
	}
}
.tribe-events-single-event-title{
	font-size:30px;
	margin:0 0 15px;
	line-height:1;
}
	
//-----Widget Event Countdown-----------
.wpo-event-countdown{
	&.layout-1{
		background: $theme-color;
		padding: 40px 30px 0;
		margin-bottom: 0 !important;
	}
	.layout-1{
		.event-title{
			.event-date{
				color: rgba(255, 255, 255, 0.3);
				font-size: 14px;
				margin-bottom: 3px;
				i{
					margin-right: 5px;
				}
			}
			.entry-title{
				margin: 0;
				a{
					font-family: $font-family-base;
					color: $white;
					font-size: 24px;
					font-weight: 700;
				}
			}
		}
		.event-time{
			background: $white;
			padding: 0 20px;
			margin-left: -30px;
			margin-right: -30px;
			position: relative;
			.label-start{
				position: absolute;
				@include size(52px, 100%);
				right: 100%;
				top: 0;
				z-index: 1;
				background: #DF124D;
				span{ 
					@include rotate(90deg);
					-webkit-transform-origin: left top 0;
     				-moz-transform-origin: left top 0;
      			-ms-transform-origin: left top 0; 
          		transform-origin: left top 0;
					position: absolute;
				   top: 0;
				   left: 52px;
				   height: 52px;
				   width: 120px;
				   z-index: 1;
				   line-height: 52px;
				   display: block;
				   text-align: center;
				   font-family: $font-family-second;
				   font-weight: 700;
				   text-transform: uppercase;
				}
			}
			.heading-time{
				padding: 15px 0;
				font-weight: 700;
				text-transform: uppercase;
				color: rgba(255, 255, 255, 0.2);
				text-align: center;
				font-size: 14px;
				border-bottom: 1px solid rgba(255, 255, 255, 0.1);
			}
			.time{
				padding: 26px 0;
				.countdown-times{
					> div{
						float: left;
						width: 25%;
						color: #1c2043;
						text-align: center;
						font-weight: 400;
						font-size: 12px;
						font-family: $font-family-second;
						b{
							display: block;
							width: 100%;
							font-weight: 700;
							font-size: 48px;
							color: #1c2043;
							line-height: 1;
							font-family: $font-family-second;
						}
					}	
				}
			}	
		}
		.event-action{

		}
	}
	
}

.tribe-events-accordion{
	.tribe_events{
		border: 0;
		border-bottom: 1px solid $border-color;
		margin: 0!important;
	}
	.panel-heading{
		position: relative;
		.heading-icon{
			float: left;
			font-size: 30px;
			color: $text-color;
			position: absolute;
			left: 30px;
			top: 50%;
			margin-top: -25px;
		}
		.arrow{
			display: block;
			position: absolute;
			top: 50%;
			right: 15px;
			margin-top: -10px;
			@include transition(all 0.25s);
		}
		.date{
			font-size: 14px;
			font-weight: 400;
			i{
				margin-right: 10px;
			}
		}
		.heading-inner{
			padding-left: 75px;
			.panel-title{
				text-transform: uppercase;
				font-weight: 700;
				a{
					font-size: 18px; 
				}
			}
		}
		&.active{
			background: $theme-color;
			.date, .heading-icon i, .panel-title{
				color: $white;
			}
			.arrow{
				color: $white;
				@include rotate(-90deg);
			}
		}
	}	
	.event-desc{
		background: none repeat scroll 0 0 $theme-color;
		padding: 25px;
		color: $white;
		line-height: 1.4;
		a{
			color: $white;
			&:hover{
				color: $black;
			}
		}
		.readmore{
			font-weight: 700;
		}
	}
	.event-body{
		padding: 20px 0;
		.left{
			width: 80px;
			img{
				width: 100%;
			}
			float: left;
		}
		.right{
			padding-left: 90px;
			line-height: 1.2;
			.readmore{
				margin: 5px 0 0;
				a{
					font-weight: 700;
					font-size: 12px;
					color: $theme-color;
					text-transform: uppercase; 
					&:hover{
						color: $black;
					}
				}	
			}
		}
	} 

	.tribe-events-content{
		.event-detail-tab {
			&.active{
				display: block;
			}
		}
		.event-title{
			padding: 25px;
			background: $theme-color;
			text-align: center;
			.event-date{
				color: rgba(255, 255, 255, 0.3); 
				font-size: 14px;
				margin-bottom: 3px;
				i{
					margin-right: 5px;
				}
			}
			.entry-title{
				text-align: center;
				margin: 0;
				a{
					color: $white;
					font-size: 18px;
					font-weight: 700;
				}
			}
		}
		.event-time{
			background: $theme-color-second;
			padding: 0 20px;
			.heading-time{
				padding: 15px 0;
				font-weight: 700;
				text-transform: uppercase;
				color: rgba(255, 255, 255, 0.2);
				text-align: center;
				font-size: 14px;
				border-bottom: 1px solid rgba(255, 255, 255, 0.1);
			}
			.time{
				padding: 30px 0;
				.countdown-times{
					> div{
						float: left;
						width: 25%;
						border-right: 1px solid rgba(255, 255, 255, 0.1);
						color: rgba(255, 255, 255, 0.2);
						text-align: center;
						font-weight: 700;
						font-size: 14px;
						b{
							display: block;
							width: 100%;
							font-weight: 700;
							font-size: 30px;
							color: $white;
						}
						&:last-child{
							border: none;
						}
					}	
				}
			}	
			.lof-labelexpired{
				color: $white;
				text-align: center;
			}
		}
	}
}

.tribe-events-month{
	table.tribe-events-calendar {
		thead {
			tr {
				th {
					font-size: 14px;
					@media screen and (max-width: $screen-xs-min) {
						padding-left: 0;
						padding-right: 0;
					}
				}
			}
		}
		tbody {
			td {
				position: relative;
				.type-tribe_events {
					padding: 10px 8px !important;
					border-bottom: none!important;
				}
			}
			div[id*="tribe-events-daynum-"] {
				display: inline-block;
				font-weight: 400;
				padding: 0;
				@include square(36px);
				line-height: 36px;
				text-align: center;
				color: $black;
				background: #f2f2f2;
				position: absolute;
				content: "";
				top: -1px;
				left: -1px;
				a {
					font-size: 18px;
					font-weight: 400;
				}
				@media screen and (max-width: $screen-xs-max) {
					left: 0;
					right: 0;
					top: 0;
					margin: auto;
					font-size: 14px;
					text-align: center;
					@include size(auto,28px);
					line-height: 28px;
					a {
						font-size: 14px!important;
					}
				}
			}
			td.tribe-events-present,
			td.tribe-events-has-events {
				background: $theme-color;
				@include transition(all 0.3s ease 0s);
				div[id*="tribe-events-daynum-"] {
					background: #f2f2f2;
					@include transition(all 0.3s ease 0s);
					color: #000;
					a {
						font-size: 18px;
						background: none;
						font-weight: 400;
						color: #000;
						@media screen and (max-width: $screen-xs-max) {
							font-size: 14px;
						}
					}
				}
				.tribe-events-month-event-title {
					a {
						color: $white;
						font-size: 12px;
						font-family: $font-family-base;
						font-weight: 500;
						@include transition(all 0.3s ease 0s);
						&:hover {
							color: #fff;
							@include transition(all 0.3s ease 0s);
						}
					}
				}
				&:hover {
					background: $theme-color-second !important;
					 @include transition(all 0.3s ease 0s);
				}
				&:after {
					content: none;
				}
			}
			td.tribe-events-present {
				background-color: $theme-color-second;
				div[id*="tribe-events-daynum-"] {
					background-color: lighten($theme-color-second, 15%);
					a{
						color: #fff;
					}
				}
			}
			td.tribe-events-past{
				background-color: lighten($theme-color, 15%);
			}
		}
	}
	#tribe-events-header{
		margin: 0!important;
	}
	table{
		margin: 0!important;
	}
	
	table.tribe-events-calendar{
		border: none!important;
		border-spacing: 10px!important;
		border-collapse: separate!important;
		thead tr th{
			background: #F2F2F2!important;
			border: none!important;
			color: $black;
			font-weight: 600;
		} 
		tr td{
			border-top:1px solid $border-color!important;
			
		}
	}
	.tribe-events-thismonth{
		padding: 40px 0!important;
		&.tribe-events-present{
			position: relative;
			&:after{ 
				position: absolute;
				@include size(12px, 12px);
				@include border-radius(50%);
				background: $theme-color;
				content: "";
				top: 20px;
				right: 20px;
				z-index: 99; 
			}
		}
	}
	.tribe-month-single-day{
		position: relative;
		height: 100%;
		.tribe-month-daynumber {
			background: none!important;
			padding: 0; 
			a{
				@include size(38px, 38px);
				padding-top: 8px;
				text-align: center;
				display: block;
				background: #F2F2F2!important;
				color: $black!important;
				font-size: 18px!important;
				font-weight: 400!important;
			}
		}
		.tribe-month-sigle-day-list{
			background: $white;
			padding: 18px;
			position: absolute;
			min-width: 430px;
			z-index: 9999;
			top: 100%;
			left: 0;
			@include box-shadow(0px 2px 4px 2px rgba(0, 0, 0, 0.3));
			
			&:before{
				content: "";
				border: 10px solid $nocolor;
				position: absolute;
				border-bottom-color: $white;
				bottom: 100%;
				left: 18px;
			}
			> div{
				width: 100%;
				padding: 0!important;
				margin: 0 0 10px!important;
				border: none!important;
				.entry-title a{
					color: #5d5d5d!important;
					font-size: 14px;
					font-weight: 700!important;
					text-transform: uppercase;
					padding-bottom: 5px;
					&:hover{
						color: $theme-color!important;
					}
				}
				.date{
					color: #b3b3b3;
					font-size: 12px;
					i{
						margin-right: 8px;
					}
				}
				.thumbnail{
					@include size(65px, auto);
					float: left;
					margin: 0 10px 0!important;
				}
			}
		}
		.tribe-events-viewmore{
			border: none!important;
			display: inline-block;
			text-transform: none;
			background: $theme-color;
			@include border-radius(3px);
			padding: 2px!important;
			line-height: 1;
			margin-top: 20px;
			a{
				color: #5d5d5d;
				font-weight: 400!important;
			}
			&:hover a, &:focus a{
				color: $black;
			}
		}
		&:hover, &:focus{
			background: $theme-color;
			cursor: pointer;
			@include box-shadow(none);
			.tribe-month-sigle-day-list{
				display: block;
			}
			.tribe-events-viewmore{
				background: $white!important;
			}
		}
	}
	#tribe-events-footer{
		ul{
			padding: 0!important;
		}
	}	
}

#tribe-events-content {
	.tribe-events-tooltip {
		padding: 20px 30px;
		width: 500px;
		h4 {
			color: $black;
			font-weight: 400;
			margin: 0 0 10px;
			font-size: 20px;
			line-height: 28px;
		}
		.tribe-events-event-body {
			font-size: 14px;
			color: #666;
			font-weight: 300;
			p {
				line-height: 26px;
				margin-bottom: 0;
			}
			.tribe-event-duration {
				font-size: 12px;
				color: #aaa;
				margin-bottom: 20px;
			}
			.tribe-events-event-thumb {
				float: left;
				width: 125px;
				margin-right: 20px;
				padding: 0;
			}
		}
	}
}

.tribe-events-day {
	.tribe-events-loop {
		.tribe-events-day-time-slot {
			h5 {
				margin: 0 0 30px;
				background-color: transparent;
				color: $theme-color;
				padding: 10px;
				font-weight: 500;
				font-family: $font-family-second;
				font-size: 24px;
			}
			.type-tribe_events {
				margin: 0;
			}
			.tribe-events-read-more {
				color: $theme-color;
			}
		}
	}
}

.events-list.tribe-bar-is-disabled #tribe-events-content-wrapper {
	max-width: 1170px;
}
.tribe-bar-disabled {
	z-index: 0;
	float: none;
}

.tribe-events-single {
	.tribe-events-single-event-title {
		margin: 0 0 15px!important;
	}
	.tribe-events-schedule {
		margin: 0 0 15px;
		h2 span {
			color: $light-gray;
			font-size: 14px;
			position: relative;
			font-weight: 400;
			&:before {
				content: "\f017";
				font-family: 'FontAwesome';
				color: #d3d3d3;
				margin-right: 10px;
			}
		}
	}
}
#tribe-mobile-container {
	.type-tribe_events {
		.tribe-events-read-more{
			color: $theme-color;
			&:hover{
				text-decoration: underline;
			}
		}
	}
	.tribe-mobile-day {
		.tribe-mobile-day-heading{
			background-color: $theme-color;
			padding: 10px 20px;
			color: #fff;
			font-family: $font-family-second;
			text-align: center;
			font-size: 30px;
			text-transform: none;
		}
	}
}
//Event list style default
.event-item-list{
	&.style-default{
		min-height: 100px;
		margin-bottom: 30px;
		background: $white;
		border-left: 2px solid $theme-color;
		&:last-child{
			margin-bottom: 0;
		}
		.event-date{ 
			width: 100px;
			min-height: 100px;
			float: left;
			background: $white;
			padding-top: 18px;
			border-right: 1px solid $border-color;
			span{ 
				display: block;
				text-align: center;
				color: $theme-color;
				font-family: $font-family-second;
				text-transform: uppercase;
				line-height: 1;
				&.date{
					font-size: 36px;
					position: relative;
					margin-bottom: 5px;
					padding-bottom: 5px;
					&:after{
						content: "";
						@include size(40px, 1px);
						position: absolute;
						background: $theme-color;
						bottom: 0;
						left: 50%;
						margin-left: -20px;
						z-index: 1;
					}
				}
			}
		}
		.event-header{
			padding-left: 120px;
			padding-top: 30px;
			.event-title{
				margin: 0 0 5px;	
			}
		}
		.event-meta{
			margin-bottom: 10px;
			overflow: hidden;
			> span{
				font-size: 12px;
				color: #aaaaaa;
				float: left;
				text-transform: uppercase;
				margin-right: 20px;
				font-family: $font-family-second;
				i{
					margin-right: 5px;
				}
				&:last-child{
					margin-right: 0;
				}
			}
		}
	}
}

.widget-event-slide{
	.owl-item{
		padding: 0!important;
	}
	.item-event{
		position: relative;
		.event_heading{
			color: $white;
			text-align: center;
			position: absolute;
			width: 100%;
			left: 0;
			top: 50%;
			margin-top: -100px;
			h2{ 
				margin: 0 0 30px;
			}
			a{
				color: $white;
			}
			.event-title{
				font-size: 70px;
				font-weight: 300;
				font-family: $font-family-base;
			}
			.event-des{
				font-size: 14px;
				font-family: $font-family-second;
				padding-left: 20%;
				padding-right: 20%;
				@media(max-width: $screen-sm-max){
					padding-left: 15px;
					padding-right: 15px;
				}
			}
		}
		.event-meta{
			position: absolute;
			bottom: 0;
			width: 100%;
			left: 0;
			.event-meta-inner{
				background: $white;
				position: relative;
				overflow: hidden;
				padding-left: 55px;
				width: 75%;
				margin: 0 auto;
				@media(max-width: $screen-sm-max){
					width: 100%;
				}
				>*{
					float: left;
					display: inline-block;
					&.time{
						width: 70%;
						width: calc(100% - 225px);
						width: -ms-calc(100% - 225px);
						width: -moz-calc(100% - 225px);
						width: -webkit-calc(100% - 225px);
						.countdown-times > *{
							width: 25%;
						}
					}
				}
			}	
		}
		.event-label{
			background: #DF124D;
			color: $white;
			font-size: 14px;
			font-weight: 700;
			font-family: $font-family-second;
			@include size(120px, 52px);
			position: absolute;
			top: 0;
			left: 0;
			@include rotate(-90deg);
			text-transform: uppercase;
			font-size: 14px;
			display: block;
			margin-left: -35px;
			margin-top: 28px;
			padding-top: 13px;
			text-align: center;
			span{

			}
		}
		.pts-countdown{
			.countdown-times{
				width: 100%;
				> div{
					text-align: center;
					padding: 20px 0 15px;
					float: left;
					font-size: 12px;
					font-weight: 400;
					color: #1c2043;
					font-family: $font-family-second;
					b{ 
						font-size: 48px;
						display: block;
						color: #1c2043;
						line-height: 1;
						font-family: $font-family-second;
						font-weight: 700;
					}
					&:first-child{
						margin-left: 0;
					}
				}
			}	
		}
	}	
}

.wpo-event-frontend{
	.image{
		position: relative;
		.excerpt{
			position: absolute;
			top: 20px;
			bottom: 20px;
			left: 20px;
			right: 20px;
			@include opacity(0);
			@include scale(0);
			@include transition(all 0.35s);
			background: rgba(255, 255, 255, 0.8);
			padding: 30px;
		}
	}	
}
// meta-details
.meta-details{
	margin:0;
	li{
		font-size:14px;
		padding:8px 0;
		color: $light-gray;
		.tribe-events-span{
			float: right;
			color:#000;
			letter-spacing: -0.5px;
		}
	}
}
//tribe-events-meta-group-venue
.tribe-events-meta-group-venue{
	.tribe-address {
		font-size: 14px;
	}
	
	.tribe-events-address{
		margin:0 0 10px; 
	}
	.tribe-events-gmap{
		font-size: 12px;
		color: $theme-color-second;
		text-transform: uppercase;
	}
}
// event countdow
.widget-events-time{
	.feature-image{
		max-width: 670px;
		width: 100%;
	}
	.tribe-events-list-event-title{
		margin-top: 0;
	}
	&.style-lighten{
		.tribe-event-url{
			color: #fff;
			&:hover{
				color: $theme-color;
			}
		}
	}
	.event-name{
		div{
			display: inline-block;
		}
	}
	.owl-controls{
		margin-top: $theme-margin;
	}
	.pbr-countdown-time{
		position:absolute;
		top:0;
		right: 0;
	}
}

.pbr-countdown-time{
	display:inline-block;
	font-family: $font-family-serif;
	font-size: 12px;
	text-transform: uppercase;
	background:#fff;
	text-align:center;
	font-weight: 400;
	.title-time{
		font-size: 14px;
		padding:10px;
		border-bottom: 1px solid $border-color;
	}
	.countdown-times{
		> div{
			min-width: 100px;
			border-bottom:1px solid $border-color;
			padding:10px;
			&:last-child{
				border:none;
			}
			> b{
				font-size:30px;
				font-weight: 400;
				color: #000;
				display: block;
				line-height: 1;
			}
		}
	}
}

.widget {
	&.tribe-events-meta-group {
		background-color: #f8f8f8;
		padding: 30px;
		.widget-title{
			border-bottom: none!important;
		}
		ul.meta-details {
			li {
				border-bottom: 1px dotted $border-color;
				&:last-child{
					border-bottom: none;
				}
			}
		}
		.tribe-organizer{
			color: $theme-color-second;
			text-transform: uppercase;
		}
	}
	&.widget-addreas {
		margin-bottom: 0 !important;
		.meta-group-venue {
			.venue {
				span{
					color: $theme-color-second;
					text-transform: uppercase;
				}
			}
		}
	}
	&.widget-events-list {
		.event-item {
			border-top: 1px solid $border-color;
			padding-top: 40px;
			margin-top: 40px;
			.entry-date {
				width: 70px;
				margin-right: 30px;
				background-color: transparent;
				border: 1px solid $theme-color;
				color: $black;
				padding: 0;
				text-align: center;
				span {
					display: block;
					&.date {
						font-weight: 700;
						font-size: 30px;
						border-bottom: 1px solid $theme-color;
						line-height: 58px;
					}
				}
			}
			.tribe-events-inner {
				overflow: hidden;
				padding-left: 1px;
				.tribe-events-list-event-title {
					font-size: 20px;
					font-weight: 400;
					margin: -5px 0 5px;
					line-height: 28px;
				}
				.author {
					margin: 0;
					position: relative;
					&:before {
						font-family: 'FontAwesome';
						content: "\f041";
						margin-right: 5px;
					}
				}
			}
			&:first-child {
				border-top: none;
				padding-top: 0;
				margin-top: 0;
			}
		}
	}
}


/* widget events List */
.tribe-list-widget{
	padding: 0;
	list-style: none;
	.tribe-item{
		padding-left: 100px;
		position: relative;
		margin-top: 50px;
		
	}
	.tribe-event-title{
		font-size: 16px;
		font-weight: 600;
		line-height: 1.4;
		text-transform: none;
		margin-top: 10px;
		max-height: 45px;
		overflow: hidden;
	}
	.tribe-event-duration{
		font-size: 12px;
		color: #999;
		.location{
			margin-left: 8px;
			padding-left: 10px;
			position: relative;
			&:before{
				position: absolute;
				content: "";
				top: 50%;
				margin-top: -6px;
				left: 0;
				@include size(1px, 12px);
				background-color: #000;
			}
		}
	}
	.tribe-event-time{
		background-color: $theme-color-second;
		color: #fff;
		@include size(75px, 100%);
		text-transform: uppercase;
		font-size: 24px;
		font-family: $font-family-second;
		line-height: 1;
		position: absolute;
		font-weight: 500;
		top:0;
		left: 0;
		border-right: 1px solid rgba(#fff, 0.3);
		.day {
			font-size: 60px;
			display: block;
		} 
		.month {
			display: block;
			line-height: 1.7;
		}
	}
}

/* widget_wpopal_eventlist_widget */
.sidebar {
	.widget_wpopal_eventlist_widget {
		.widget-title{
			border-bottom: none;
		}
	}
}
.single-tribe_events,
.events-archive{
	.site-content{
		margin-bottom: 60px;
	}
}
.tribe-events-meta-group-venue,
.tribe-events-meta-group-details{
	ul{
		list-style: none;
		 padding: 0;
	}
}

.tribe-light{
	.tribe-list-widget{ 
		color: #ffffff;
		.tribe-event-duration{
			color: #000;
			font-style: italic;
    		font-weight: 500;
		}
		.tribe-event-title{
			a{
				color: #fff;
				text-transform: uppercase;
				font-weight: 500;
				&:hover{
					text-decoration: underline;
				}
			}
		}
	}
}