/////////// TEMPLATES LAYOUT ///////////

$app-prefix                      : opal !default;
$vc-prefix                       : wpopal_ !default;


#main {
    .container{ 
        position:relative; 
        .container {
            width: 100%;
        }
    }
}
#site-header{
    text-align: center;
}
.home-background{
    .site-main{
        background: $gray-lighter; /* For browsers that do not support gradients */
        background: -webkit-linear-gradient($gray-lighter, $white); /* For Safari 5.1 to 6.0 */
        background: -o-linear-gradient($gray-lighter, $white); /* For Opera 11.1 to 12.0 */
        background: -moz-linear-gradient($gray-lighter, $white); /* For Firefox 3.6 to 15 */
        background: linear-gradient($gray-lighter, $white); /* Standard syntax (must be last) */
    }
}
/**
 * Page Style
 */
#page{
    position: relative;
    margin-left:auto;
    margin-right: auto;
    overflow: hidden;
    &.page-static-left{
        @include transition(all 0.25s ease-out);
        &.active{
            @include transition(all 0.25s ease-out);
            margin-left: 250px;
            .sidebar-static-left{
                @include transition(all 0.25s ease-out);
                left: 0;
            }
        }
    }
    &.active .button-action:before{
        content: "\f060"
    }
}

.sidebar-static-left{
    background:#282828;
    color: #CCC;
    position:fixed;
    left: -290px;
    height: 100%;
    position: fixed;
    width: 300px;
    z-index: 99999;
    @include transition(all 0.25s ease-out);
    .inner{
        padding:30px; 
        width: 100%;
        position: absolute;
        height: 100%;
    
    }

    .button-action{
        background:red;
        position:absolute;
        right: -40px;
        top: 0;
        height: 40px;
        color: #CCC;
        &:before{
            content: "\f061";
            font-size: 12px;
            height: 38px;
            width: 38px;
            font-family: 'FontAwesome';
            text-align: center;
            display: block;
            line-height: 34px;
        }
    }
}
/**
 * Topbar layout styles
 */
##{$app-prefix}-topbar{    
    text-transform : $topbar-text-transform;
    font-size      : $topbar-font-size ;
    background-color: #f7f7f7;
   	.list-inline{
   		margin: 0; padding:0;
   	}
    .user-list{
        list-style: none;
        margin: 0;
        padding:0;
        li{  
            float: left;
            padding-right: 15px;
            text-align: center;
        }
    }
    a{
        color: $topbar-link-color;
        &:hover{
            color: #000;
        }   
    }
    &.topbar-light{
        background-color: #383838;
        color: #cccccc;
        a{
            color: #cccccc;
            &:hover{
                color: $theme-color;
            }
        }
    }    
}
.opal-topbar{
    padding: $topbar-padding;
}
.topbar-mobile{
    width: 100%;
    display: table;
    padding: 20px; 
    .active-offcanvas{   
        background-color:$theme-color;
        @include border-radius(50%);
        margin-right: 20px;
    }
    .btn-offcanvas{
        background-color:transparent;  
        @include border-radius(0);
        padding: 0;
        width: 48px;
        i{
            line-height: 48px;
        }
    }
}

.opal-search{
    .input-search{
        border: 1px solid #666;
    } 
    .btn-search {
        padding: 0;
        background-color: #666;
        border-color: #666;
        .fa{
            color: $white;
            border: none;
            font-size: 16px;
            background-color: transparent;
        }
        &:hover{
            .fa{
                color: $theme-color;
            }
        }
    }
}
.active-mobile{
    line-height:48px;
    @include size(48px,48px);
    position: relative;
    cursor: pointer;
    @include transition(all 0.3s ease 0s);
    text-align: center;
    &:hover{
        background-color: darken($theme-color, 5%) !important;
        cursor: pointer;
        color: #ffffff;
        .active-content{
            display: block;
            opacity: 1;
            visibility: visible;
            right: 0;
        }
    }
    .active-content{
        position: absolute;
        top: 100%;
        z-index: 99999;
        color: $white;
        padding: $theme-margin;
        background-color: #f8f6f0;
        right: -10000px;
        @include opacity(0);
        position: absolute;
        min-width: 270px;
        visibility: hidden;
        right: 0;
        text-align: left;
        @include transition(opacity 0.3s ease 0s);
        p.total{
            color: $gray;
        }
        .account-links .dropdown-menu {
            display: none;
        }
    }
    ul{
        padding: 0;
        li{
            display: block;
            margin: 0;
            padding: 5px 0;
            line-height: normal;
        }
    }
}
.logo-wrapper{
    position: relative;
    z-index: 10;
}
.search-box-wrapper{
    position: relative;
    z-index: 10;
}
.box-user {
    position: relative;
    z-index: 3;
    .account-links{
        padding: 0 20px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    &.open{
        > .dropdown-toggle{
            color: $theme-color!important;
        }
    }
}
/**
 * Header Layout styles
 */
##{$app-prefix}-masthead{
    position: relative;
    .header-main{
        padding: 50px 0;
        text-align: center;
        color: $gray-darker;
        @media (min-width: $screen-lg) {
            text-align: left;
        }
    }
    &.header-absolute-v1{
        position: absolute;
        width: 100%;
        z-index: 9999;
        @media (max-width: $screen-sm-max){
            position: relative;
            .header-main{
                background:#134687;
            }
        }
    }
    &.header-absolute{
        position: absolute;
        width: 100%;
        z-index: 9999;
        @media (max-width: $screen-sm-max){
            position: relative;
            .header-main{
                background:#134687;
            }
        }
        .search-box-wrapper .wpo-search {
            color: #fff;
            &:hover{
                color: $theme-color;
            }
        }
        .search-box-wrapper .opal-search{
            background-color: rgba(#fff, 1);
            .btn-search input{
                color: #000;
                
            }
            .input-search {
                color: #000;
               @include placeholder(#000);
               &:hover{
                    color: $theme-color;
                }
            }
        }
        .box-user{
            > .dropdown-toggle{
                padding: 10px 0;
                margin: 0 10px;
                cursor: pointer;
                color: #fff;
                display: inline-block;
                &:hover{
                    color: $theme-color;
                }
            }
        }
    }
    &.header-revolution{
        .header-main{
            border-bottom: 1px solid $border-color;
            padding: 30px 0;
        }
        .box-user {
            .wpo-user {
                height: 50px;
                border: medium none;
                padding: 0;
                transition: all 0.35s ease 0s;
                @include opacity(1);
                width: 40px;
                line-height: 50px;
                text-align: center;
                cursor: pointer;
                color: #000;
                font-size: 14px;
                display:  inline-block;
                &:hover{
                    color: $theme-color;
                }
            }
        }
    }
    &.header-v2{
        margin-bottom: 0;
         .header-main{
            padding: 0;
            background-color: #282b38;
        }
        .header-inner{
           padding-left: 30%;
            display: table;
            width:100%;
            position: relative;
        }
        .logo-wrapper{
            position:absolute;
            top: 0;
            left: 0;
            content: "";
            background-color: lighten($theme-color, 3%);
            padding: 30px;
            line-height: 85px;
        }
        .mainmenu-inner{
            padding-left: 30%;
            display: table;
            width:100%;
             > div{
                vertical-align: middle;
                display: table-cell;
            }
            .navbar-mega-collapse{
                padding: 0;
            }
            .navbar-mega .navbar-nav > li:first-child{
                margin-left: 0;
            }
            .navbar-mega .navbar-nav > li > a{
                padding: 38px 0;
                font-weight: 700;
                color: #000;
                &:hover{
                    color: $theme-color;
                }
            }
        }
        .button-header{
            a.btn {
                background-color: #151a20;
                line-height: 42px;
                @include border-radius(0);
                padding: 0 20px;
                border: none;
            }
        }
        .headerphone{
            font-size: 18px;
        }
        .header-tool{
            color: #fff;
            line-height: 42px;
            a{
                color: #999;
            }
            ul{
                margin: 0;
            }
        }
    }
    .has-sticky {
        &.keeptop{
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 9999;
            background-color: rgba(#333, 0.95);
            padding: 30px 0;
            left: 0;
            
            .navbar-mega .navbar-nav > li{ 
                > a{
                    color: #fff;
                    .caret {
                        color: #fff;
                    }
                }
                &.active,
                &:hover,
                &:focus{
                    > a{
                        color: $theme-color;
                        .caret {
                            color: $theme-color;
                        }
                    }  
                }
            }
            .search-box-wrapper .wpo-search{
                color: #fff;
                &:hover{
                    color: $theme-color;
                }
            }
        }
    }
}
.opal-header-right{
    .cart-icon{
        color: white;
    }
}

/**
 * Mass Bottom Header Styles
 */

/**
 * Main menu styles
 */
##{$app-prefix}-mainmenu{
    background: transparent;
    position: relative;
    .inner{
 
    }
}

/**
 * Breadscrumb content style
 */
##{$app-prefix}-breadscrumb{
    color: #eee;
    font-size: $font-size-base - 2px;
    position: relative;
    margin-bottom: 50px;
    font-family: $font-family-second;
    background-image: url('#{$image-theme-skin}bg-breadscrumb.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    font-weight: 400;
    z-index: 2;
    padding:  115px 0;
    text-align: right;
    .breadcrumb {
        margin-bottom: 0;
        position: relative;
        z-index: 4;
    }
    a {
        color: #ddd;
        &:hover{
            color: $theme-color;
        }
    }
    li{
        position: relative;
        z-index: 2;
    }
    @media (min-width: $screen-md){
        .active {
            display: block;  
            content: "";
            color: $theme-color;
            text-transform: none;
            font-weight: 700;
            letter-spacing: -2px;
            position: absolute;
            font-family: $font-family-second;
            margin: 0;
            top: -27px;
            font-size: 54px;
            z-index: 4;
        }
    }
}

/**
 * Footer layout styles
 */

.#{$app-prefix}-footer{    
    color:$footer-color;
    background: $footer-bg;
    font-size: 14px;
    .footer-top{
        padding: 70px 0;
    }
    a{
        color: #ccc;
        &:hover{
            color: #fff;
        }
    } 
    ul, ol{
        list-style: none;
        padding: 0;
        li{
            padding: 5px 0;
            &:first-child {
                padding-top: 0;
            }
        }
    }
    .widget_wpopal_socials_widget ul.social li a {
        color: #999;
        &:hover{
            color: $theme-color;
        }
        i{
            @include size(42px, 42px);
            background-color: #f6f6f6;
            @include border-radius(4px);
            line-height: 42px;
        }
    }
}

/*------------------------------------*\
    Copyright
\*------------------------------------*/
.#{$app-prefix}-copyright{
    font-size: $copyright-font-size;
    border-top: 1px solid rgba(#fff, 0.1);
    padding: 30px 0;  
    text-align: center;  
    .#{$block-selector} {
        .#{$block-heading-selector}{
            color: $footer-heading-color;
            text-transform: $footer-heading-transform;
            margin: $footer-heading-margin;
            @include font-size(font-size,$footer-heading-font-size);
        }
    }
    a{
        color: $theme-color;
        &:hover,
        &:focus,
        &:active{
            text-decoration: underline;
        }
    }
}