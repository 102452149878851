.kingcomposer{
	.kc_row_inner, .kc-row-container.kc-container .kc-wrap-columns{
		margin-left: -5px;
		margin-right: -5px;
	}
	.kc_row.kc_row_inner{
		width: calc(100% + 10px);
	}
	.kc_column,.kc_column_inner{
		padding-right: 5px;
	    padding-left: 5px;
	}
	body div.kc_row_inner[data-kc-equalheight="true"]{
		width: calc(100% + 10px);
	}
	@media screen and (min-width: 999px){
		.kc_row_inner, .kc-row-container.kc-container .kc-wrap-columns{
			width: calc(100% + 10px);
		}
	}
	@media(min-width: $screen-md-max){
	   .kc-container{
	      padding-left: 0;
	      padding-right: 0;

	   }
	}
}
.kingcomposer .site-main{
   margin-left: -$grid-gutter-width / 2;
   margin-right: -$grid-gutter-width / 2;
} 

.kc-fb-layout-3 .box-right{
	padding-left: 10px!important;
}
/* KC Call to action */
div.kc-call-to-action{ 
	.kc-cta-button{
		a {
			@include border-radius( 35px );
			padding: 13px 35px;
			font-family: $font-family-second;
			font-size: 14px;
			font-weight: 700;
			background-color: $theme-color;
			color: #fff;
			&:hover{
				background-color: darken($theme-color, 10%);
			}
		}
	}
}
/* KC Button */
.kc_button {
	font-family: $font-family-second;
	font-size: 14px;
	font-weight: 700;
	padding: 13px 35px;
}
/* KC Feature Box  */
.kc-feature-boxes .content-button {
	a {
		@include border-radius( 35px );
		padding: 13px 35px;
		font-family: $font-family-second;
		font-size: 14px;
		font-weight: 700;
		background-color: $theme-color;
		color: #fff;
		&:hover{
			background-color: darken($theme-color, 10%);
		}
	}
}
/* kc tooltip */
.kc-popover-tooltip{
	.content-button {
		a {
			@include border-radius( 35px );
			padding: 13px 35px;
			font-family: $font-family-second;
			font-size: 14px;
			font-weight: 700;
			background-color: $theme-color;
			color: #fff;
			&:hover{
				background-color: darken($theme-color, 10%);
			}
		}
	}
}
/* Kc Pricing  */
.kc-pricing-tables{
	.content-desc{
		padding: 0;
	}
	.content-button{
		a {
			@include border-radius( 35px );
			padding: 13px 35px;
			font-family: $font-family-second;
			font-size: 14px;
			font-weight: 700;
			background-color: $theme-color;
			color: #fff;
			&:hover{
				background-color: darken($theme-color, 10%);
			}
		}
	}
}
/* KC image effects */
.overlay-effects{
	.content-button{
		a {
			@include border-radius( 35px );
			padding: 13px 35px;
			font-family: $font-family-second;
			font-size: 14px;
			font-weight: 700;
			background-color: $theme-color;
			color: #fff;
			&:hover{
				background-color: darken($theme-color, 10%);
			}
		}
	}
}
/* Kc Testimonial */
div.kc-testimo {
	.content-desc{
		font-style: italic;
	}
	&.kc-testi-layout-3,
	&.kc-testi-layout-1{
		.content-image,  
		.content-title, 
		.content-position,  
		.content-desc {
			float: none!important;
			display: inline-block;
		}
	}
}



/** Kc Title **/
.kc-title-wrap {
	h2.kc_title {
		font-size: 44px;
		line-height: 48px;
		color: #000;
		text-transform: none;
		position: relative;
		padding-top: 40px;
		margin-top: 0;
  		&:before{
            content: url('#{$image-theme-skin}bg-title.png');
            display: block;
            @include size(100%, 8px);
            line-height: 8px;
            position: absolute;
            top: 0;
        }
    }
    h3.kc_title {
    	font-size: 30px;
    }
    div.kc_title {
    	font-size: 30px;
    	font-family: $font-family-second;
    	font-weight: 700;
    	color: #000;
    }
    span.kc_title{
    	font-size: 14px;
    	text-transform: uppercase;
    	color: $theme-color;
    	font-family: $font-family-second;
    	font-weight: 500;
    }
	p.kc_title{
		font-size: 20px;
		color: #999;
		font-weight: 300;
		font-family: $font-family-second;
		position: relative;
		margin-bottom: 20px;
	}
}

/**  kc tabs  **/
ul.kc_tabs_nav ~ .kc_tab.ui-tabs-body-active {
    background: transparent;
}

ul.kc_tabs_nav ~ .kc_tab .kc_tab_content {
    padding: 0px ;
}
ul.kc_tabs_nav ~ .kc_tab{
	padding:  30px;
	overflow: hidden;
}
.kc_tabs>.kc_wrapper>ul.kc_tabs_nav {
    background: transparent;
}

.kc_tabs{
	.kc_tabs_nav > li {  
	    color: #999;
	    float: none;
		display: inline-table;
		@include border-radius(30px);
		margin: 0 2px;
		> a {
			color: #999;
			font-size: 12px;
			text-transform: uppercase;
			font-family: $font-family-second;
			font-weight: 700;
		}
	}
	.kc_tabs_nav > li:hover,.kc_tabs_nav > li > a:hover,.kc_tabs_nav > .ui-tabs-active > a {
	    background-color: $theme-color;
	    color: $tab-color-active;
	}

	.kc_tabs_nav > .ui-tabs-active,
	.kc_tabs_nav > .ui-tabs-active:hover,
	.kc_tabs_nav > .ui-tabs-active > a,
	.kc_tabs_nav > .ui-tabs-active > a:hover {
	    color: $tab-color-active;
	    display: inline-table;
	}
}

.kc_accordion_wrapper{
 .kc_accordion_section{
 
  > .kc_accordion_header{
  
   span{
    pointer-events:none;
    &:before{
     content: "\f067";
              font-family: "FontAwesome";
    }
   }
   
  }
  > .ui-state-active{
  }  
 }
 .kc-section-active{
  .kc_accordion_header{
   &.ui-state-active{
    > span.ui-accordion-header-icon{
     &:before{
      content: "\f068";
       font-family: "FontAwesome";
     }
    }
   }
  }
 } 
 .kc_accordion_content{
  border: none;
  background: white;
  .kc-panel-body{
   padding: 5px 15px 15px;
  }
  .kc_text_block{
   padding: 0 25px;
   font-weight: 300;
  }
 }
}

@media(min-width: $screen-sm){
	.kc-cta-3.kc-is-button .kc-cta-desc{
		width: 70%!important;
	}
	.kc-cta-3 .kc-cta-button{
		width: 30%!important;
	}
}
@media(max-width: $screen-sm){
	.kc-cta-3.kc-is-button .kc-cta-desc{
		width: 100%!important;
		display: block!important;
	}
	.kc-cta-3 .kc-cta-button{
		width: 100%!important;
		display: block!important;
		margin-top:  30px;
		 > a {
			float: left!important;
		 }
	}
}
/*  */

.kc-feature-boxes.kc-fb-layout-5 .content-position {
    color: $theme-color;
    font-size: 14px;
    text-transform: none;
    font-style: italic;
    font-family: $font-family-third;
    font-weight: 400;
}
/*  */
.opal-footer-profile{
	overflow: hidden;
	h3{
		font-size: 24px;
	}
	.kc_text_block{
		i{
			color: $theme-color;
			min-width: 22px;
		}
	}
}
.copyright-buider{
	background-color: #ffffff;
	color: #666;
	a{
		&:hover{
			color: $theme-color;
			text-decoration: underline;
		}
	}
}