 $fonts-theme-path          : "../../../fonts/";
$fa-font-path              : "../../../fonts/";
$image-theme-path          : "../../../images/";
$image-theme-skin			: "../../../images/";
$theme-color                : #3dc7c9;
$theme-color-second         :#51bbb4;
$link-hover-color			:#3dc7c9;
$brand-primary              :#3dc7c9;
$btn-primary-color:          #fff;
$btn-font-weight: 400;
$font-weight-base: 300;

$btn-default-bg:                 $theme-color-second;
$btn-default-border:             $theme-color-second;

$font-family-sans-serif:         'Roboto',serif;
$font-family-serif:              'Roboto', sans-serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:          "Roboto",sans-serif, monospace;
$font-family-base:               'Roboto', sans-serif;
$font-family-second:             'Roboto', sans-serif;
$font-family-third:              'Taviraj', sans-serif;

$headings-font-family				: 'Roboto', sans-serif;
$headings-font-family-second		: 'Taviraj', sans-serif;
$headings-font-weight               :  400;

$tab-color-active:       #fff;

$navbar-font-weight: 400;
$color-other: #fff;