/* block */

.#{$block-selector} {
    margin-bottom: $block-module-margin-bottom;
    position: relative;
    .#{$block-heading-selector}{
        font-size: 24px;
        position: relative;
        margin: 30px 0 20px;
        text-transform: none;
        letter-spacing: -1px;
        > span{
            position: relative;
            display: block;
        }   
    }
    .#{$block-content-selector} {
    }
}
/* sidebar */
.sidebar{
   .#{$block-selector} {
        padding: 0 45px 35px;
        background-color: #fafafa;
   } 
}

/* sidebar-right */
.sidebar-right{
    margin-left: 30px;
}

/* sidebar-left */
.sidebar-left{
    margin-right: 30px;
}

/**
 * Styling Css for widgets in Sidebar Left Static
 */
.#{$block-prefix}-static{
    .#{$block-heading-selector}{
        color:#FFFFFF;
    }
    ul li { border:none }
}

/**
 * Styling Css for widgets in footer layout
 */
.opal-footer{
    .#{$block-selector} { 
        .#{$block-heading-selector}{
            color: $white;
            margin-top: 0;
            margin-bottom: 15px;
        }
    } 
}
