
/**
 * 6.3 Entry Meta
 * -----------------------------------------------------------------------------
 */
.cat-links {
/* 	font-weight: 900; */
	text-transform: capitalize;
}

.cat-links a {
	color: #2b2b2b;
}

.cat-links a:hover {
	color: #41a62a;
}

.byline {
	display: none;
}
.entry-meta {
	clear: both;
	color: $light-gray;
	font-size: 12px;
	margin-bottom: 20px;
	margin-top:10px;
	overflow:hidden;
	font-style: italic;
	font-weight: 400;
	> span{
		margin-right: 6px;
	}
	.tag-links{
		margin: 0;
	}
	i,.fa{
		font-style: normal;
	}
}
.entry-date{
	a{
		background-color: $theme-color;
		color: #000!important;
		padding: 3px 10px;
		@include border-radius(3px);
		display:  inline-block;
		&:hover{
			background-color:  darken( $theme-color, 10%) ;
		}
	}
}
.post-format,
.meta-sep,
.author,	
.entry-category,
.comments-link,
.edit-link {
	a {
		color: $light-gray;	
		&:hover{
			color: $theme-color;
		}	
	}
}
.entry-date{
	span{
		margin: 0;
	}
}
.author{
	padding: 0 5px;
}
.entry-category {
	padding: 0;
	ul {
		margin: 0;
		display: inline-block;
		padding:0;
		li {
			margin: 0;
			padding: 0;
			display: inline-block;
			list-style: none;
		}
	}
}
.readmore .read-link {
	text-transform: uppercase;
	font-size: 12px;
	font-family: $font-family-second;
	font-weight: 600;
	i{
		color: $theme-color;
		padding-left: 5px;
	}
}

/**
 * 6.4 Entry Content
 * -----------------------------------------------------------------------------
 */
.entry-group {
	margin: 0 30px 50px;
}
.entry-content,
.entry-summary,
.page-content {
	-webkit-hyphens: auto;
	-moz-hyphens:    auto;
	-ms-hyphens:     auto;
	hyphens:         auto;
	word-wrap: break-word;
}



.entry-content a,
.entry-summary a,
.page-content a,
.comment-content a {
	text-decoration: none;
}

.entry-content a:hover,
.entry-summary a:hover,
.page-content a:hover,
.comment-content a:hover,
.entry-content a.button,
.entry-summary a.button,
.page-content a.button,
.comment-content a.button {
	text-decoration: none;
}

.entry-content table,
.comment-content table {
	font-size: 14px;
	line-height: 1.2857142857;
	margin-bottom: 24px;
}

.entry-content th,
.comment-content th {
	font-weight: 700;
	padding: 8px;
	text-transform: uppercase;
}

.entry-content td,
.comment-content td {
	padding: 8px;
}

.entry-content .edit-link {
	clear: both;
	display: block;
	font-size: 12px;
	font-weight: 400;
	line-height: 1.3333333333;
	text-transform: uppercase;
}

.entry-content .edit-link a {
	color: $theme-color;
	text-decoration: none;
}

.entry-content .edit-link a:hover {
	color: #41a62a;
}

.entry-content .more-link {
	white-space: nowrap;
}
.ratings a{
	text-indent:-9999em !important;
}


.entry-content-page{ 
    .edit-link{
        background:#000;
       
        position: absolute;
        top: 0;
        right: 0;
        padding:5px 6px;
        &:before{
        	content: none;
        }
        a{
            font-size: 11px;
          color: #FFF!important;
        }  
        z-index: 10000;
    }
    position:relative;
}
/* Tag links style */
.tag-links{
	a {
	    border: 1px solid $border-color;
	    display: inline-block;
	    font-size: 14px !important;
	    margin: 5px 2px;
	    padding: 2px 15px;
	    &:hover {
		    color: $theme-color;	
		    border-color: $theme-color;
		}
	}
}
	
/**
 * Navigation links
 */
.post-navigation .nav-links{
	border-top: 1px solid $border-color;
	margin-bottom: 30px;
	margin-top: 30px;
	@include flexbox();
	.nav-links-inner{
		flex: 1 1 0;
		padding: 20px 30px 35px;
	}
	a {
		font-size: 18px;
		font-weight: 600;
		font-family: $font-family-second;
	}
	.meta-nav{
		display: block;
		text-transform: uppercase;
		color: #999;
		letter-spacing: 1px;
		font-size: 12px;
		font-weight: 300;
		position: relative;
	}
}
.comments{
	margin-bottom: 40px;
	font-family: $font-family-third;
	font-weight: 300;
	font-size: 14px;
	background-color: #fdfdfd;
	padding: 30px;
    .comment-reply-title,
    .commentform .comment-respond .title,
	.comments-title {
		font-size: 24px;
	    margin-bottom: 0;
	    position: relative;
	    color: $black;
	    margin-bottom: 10px;
	    margin-top: 0;
    }
    .the-comment{
       padding-top: 20px;
        padding-bottom: 10px;
    	.comment-box {
        	display: table-cell;
        }
	    .avatar{
	        float: left;
	        margin-right:5px;
	        img{
	            @include size(50px, 50px);
	            @include border-radius(50%);
	        }
        }
    }

    .children{
    	@media screen and (min-width: $screen-xs) {
	        .the-comment{
	            margin-left: 65px;
	        }
	        .children{
		        .the-comment{
		            margin-left: 80px;
		        }
		    }
		}
		@media screen and (max-width: $screen-xs) {
	        .the-comment{
	            margin-left: 25px;
	        }
	        .children{
		        .the-comment{
		            margin-left: 55px;
		        }
		    }
		}
    }
    .comment-author{
        color: #999;
        .comment-reply-link, .comment-edit-link{
        	color: $theme-color;
        	text-transform: uppercase;
        	 font-size: 12px;
        	 font-weight: 700;
        	 &:hover{
        	 	text-decoration: underline;
        	 }
        }
    }
    .comment-meta {
        font-size: $font-size-base - 2;
        color: #aaa;
    }
    a{
        color: $theme-color;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
    }
    ol, ul {
    	&.commentlists{
			list-style: none;
			margin: 0;
			padding: 0;
			.children{
				list-style: none;
				margin: 0;
				padding: 0;
			}
		}
    }
    .comment-navigation {
    	padding-top: 20px;
    	padding-bottom: 20px;
    	> div{
    		width: 50%;
    		float: left;
    		font-weight: 700;
    		&.next.right{
    			text-align: right;
    		}
    	}
    }
}

.post .post-thumbnail {
    display: block;
    margin-bottom: 20px;
    overflow: hidden;
}

.single-post{
	.addthis{
		float: right;
		padding-top: 10px;
	}
	a.post-link{
		background-color: #fafafa;
		border: 1px solid #ddd;
		padding: 10px 30px;
		display: inline-block;
		margin-bottom: 20px;
		width: 100%;
		text-align: center;
	}
	.addthis, .tag-links{
		margin-top: 20px;
	}
}

.blog-post {
	.blog-item.first {
	    clear: both;
	}
	.post-preview{
		position: relative;
		min-height: 50px;
	}
	.post-thumbnail{
		position: relative;
		text-align: center;
		&:before{
			content: "";
			position: absolute;
			@include size(100%, 100%);
			@include gradient-vertical(rgba(0, 0, 0, 0),  rgba(0, 0, 0, 1), 50%, 100%);
			bottom: 0;
			left: 0;
			right: 0;
			margin: 0 auto;
			z-index: 1;
		}
	}
	.entry-meta{
		position: absolute;
		content: "";
		bottom: 0;
		left: 30px;
		right: 30px;
		z-index: 4;
		color: #fff;
		a{
			color: #fff;
		}
	}
}
.bloglist-layout .entry-header .entry-title {
	font-size: 24px;
}
.post-list{
	padding: 30px 0;
	.post-preview{
		float: left;
		width: 45%;
		margin-right: 30px;
		.post-thumbnail{
			margin-bottom: 0;
		}
	}
	.entry-group{
		display: table-cell;
		margin: 0;

	}
	.entry-content{
		max-height: 95px;
		overflow: hidden;
	}
}