/*
 * Off Canvas
 * --------------------------------------------------
 */
.sidebar-offcanvas {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    z-index: 999;
    @include box-shadow(0 0 5px rgba(0, 0, 0, 0.5));
    text-align: left;
    background-color: $white;
    left: -50%;
    @include transition(all 0.3s ease-out);
    &.active {
        left: 0;
    }
}

.offcanvas-head {
    background-color: $theme-color;
    padding: 30px 10px;
    button {
        padding: 0;
        margin-right: 10px;
        @include size(30px, 30px);
    }
    span{
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
    }
}

.row-offcanvas {
    position: relative;
    @include transition(all 0.25s ease-out);
    &.active {
        left: 0;
        &:before{
             background-color: rgba(0, 0, 0, 0.3);
             position: absolute;
             @include size(100%, 100%);
            content: "";
            left: 0;
             top: 0;
             z-index: 100;
        }
    }
}

.offcanvas-showright {
    background: rgba($theme-color,0.8);
    position: relative;
    @include transition(all 0.25s ease-out);
    width: 400px;
    position: absolute;
    right: -800px;
    top: 0;
    z-index: 1000;
    display: block;
    padding: 50px 0;
    &.active {
        display: block;
        right: 0;
    }
    .showright{
        background-color: $white;
        @include size(40px,40px);
        border: none;
        margin-right: 50px;
        .fa{
            color: $theme-color;
        }
    }
}
@media screen and (max-width: $screen-md-min) {
    .row-offcanvas-right {
        right: 0;
    }
    .row-offcanvas-left {
        left: 0;
    }
    .row-offcanvas-right
    .sidebar-offcanvas {
        right: -50%;
    }
    .row-offcanvas-right.active {
        right: 50%;
    }
    .row-offcanvas-left.active {
        left: 0;
    }
} 
@media screen and (max-width: $screen-xs) {
   .sidebar-offcanvas {
        width: 80%;
        left: -85%;
    }
    .row-offcanvas-right
    .sidebar-offcanvas {
        right: -85%;
    }
    .row-offcanvas-right.active {
        right: 85%;
    }
   
} 
.navbar-offcanvas{
    border: none;
    ul#main-menu-offcanvas{
        float: none;
        display: block;
        > li{
            position: relative;
            border-bottom: 1px solid $border-color;
            display: block;
            float: none;
            .caret{
                position: absolute; 
                right: 10px;
                top: 10px;
                @include size(22px, 22px);
                border: 1px solid $border-color;
                z-index: 9;
                background: #f5f5f5;
                &:hover{
                    cursor: pointer;
                }
                &:after{
                    font-family: FontAwesome;
                    content: "\f107";
                    top: -4px;
                    left: 5px;
                    position: absolute;
                    z-index: 9;
                }
            }
            ul{
                position: relative;
                float: none;
                border: none;
                @include box-shadow(none);
                padding: 0 15px ;
                li a{
                    font-weight: 300;
                    padding-top: 10px;                
                    padding-bottom: 10px;
                    &:hover{
                        color: $theme-color;
                        background-color: transparent;
                    }
                }
            }
        }
        li.active > a{
            background: none;
            color: $theme-color;
        }
    }
}

@media screen and (max-width: $screen-xs) {
    .logo-theme{
        width: 150px;
    }
}
@media screen and (max-width: $screen-sm) {
    body .rev_slider_wrapper .tparrows, body .rev_slider_wrapper:hover .tparrows {
        display: none !important;
    }
}