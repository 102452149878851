/*!
 * Bootstrap v3.3.5 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
.text-lg{
  font-size: 24px;
  font-weight: 800;
}
 img{
    max-width: 100%;
    height:auto;
 }
 .equal-height{
    @media(min-width: $screen-md){
        @include flexbox();
    }
}
