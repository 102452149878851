
/* widget style */
.widget-style{
    &.#{$block-selector} {
        ul{
            list-style: none;
            padding: 0;
        }
        select {
            width: 100%;
        }
        & > div > ul,  > ul {
            margin-bottom: 0;
            margin-top: -8px;
            & li {
                border-top: 1px solid #ededed;
                margin: 0;
                padding: 7px 0;
                position: relative;
                transition: all 0.3s ease 0s;
                p {
                    margin-bottom: 0;
                }
                & > ul, & > ol {
                    margin-bottom: -8px;
                    margin-left: 0px;
                    margin-top: 8px;

                }

            }

        }
        & > div > ul > li:first-child, & > ul > li:first-child {
            border-top-width: 0;
        }

    } 

    &.widget_recent_entries > ul li, 
    &.widget_rss > ul li, 
    &.widget_recent_comments > ul li{
        padding: 8px 0;
    }
    &.widget_text .textwidget > ul li,
    &.widget_pages > ul li, 
    &.widget_meta > ul li, 
    &.widget_nav_menu > div > ul li, 
    &.widget_archive > ul li, 
    &.widget_categories > ul li{
        padding: 8px 0 8px 15px;
        &:before {
            content: "\f105";
            font-family: $font-icon;
            color: $theme-color;
            display: inline-block;
            height: 0;
            width: 0;
            margin: 0 15px 0 -15px;
        }
        &:last-child{ 
            padding-bottom: 0;
        }
    }
}
.widget .tagcloud {
    a {
        border: 1px solid $border-color;
        display: inline-block;
        font-size: 14px !important;
        margin: 5px 2px;
        padding: 5px 15px;
        &:hover {
            color: $theme-color;
            border-color: $theme-color;
        }
    }
}


.widget_calendar {
    caption {
        font-weight: 700;
        margin-bottom: 8px;
        text-align: center;
    } 
    table {
        width: 100%;
        font-size: 14px;
        th, td {
            text-align: center;
            vertical-align: middle;
            a{
                font-weight: 700;
            }
        }
        td#prev {
            padding: 8px 0;
            text-align: left;
        }
        td#next {
            padding: 8px 0;
            text-align: right;
        }
    }
}
/* wpopal latest posts  */
.widget_wpopal_latest_posts{
   .blog-post .item{
        display: table;
        padding: 20px 0;
         border-bottom: 1px solid #ededed;
        &:first-child{
            padding-top: 0;
        }
        &:last-child{
            padding-bottom: 0;
            border-bottom: none;
        }
    }
    .image-thumnail{
        display: table-cell;
        width: 80px;
        float: left;
        margin-right: 15px;
    }
    .bottom-blog {
        display: table-cell;
        vertical-align: middle;

    }
    .entry-title{
        font-size: 14px;
        margin: 0;
    }
    .entry-date, .entry-content{
        display: none;
    }
}
/* widget_search */
.widget_search {
    padding-top: 20px!important;
}
/* widget socials */
.widget_wpopal_socials_widget{
    ul.social{
        li a{
            i{
                text-align: center;
            }
            span{
                display: none;
            }
        }
    }
    &.social-header{
        ul.social li{ 
            font-size: 14px;
            margin-left: 5px;
            a{
                i {
                    background-color: transparent;
                    line-height: 1;
                    @include size(auto, auto);
                    margin: 0;
                }
            }
        }
    }
}
/* search header */
.search-box-wrapper{
    .wpo-search{
        height: 50px;
        border: medium none;
        padding: 0;
        transition: all 0.35s ease 0s;
        @include opacity(1);
        width: 40px;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
        color: #000;
        font-size: 14px;
        &:hover{
            color: $theme-color;
        }
    }

    .opal-search{
        position: relative;
        width: 0;
        transition: all 0.35s ease 0s;
        border: 1px solid rgba(255, 255, 255, 0.3);
        @include border-radius (5px);
        background-color: rgba(0, 0, 0, 0.6);
        
        .input-search {
            height: 52px;
            background-color: rgba(0, 0, 0, 0);
            border: medium none;
            @include opacity(0);
            padding: 0 45px 0 10px;
            position: absolute;
            right: 0;
            transition: all 0.35s ease 0s;
            visibility: hidden;
            width: 0;
            border-radius: 3px;
            z-index: 8;
            color: #eaeaea;
        }
        .btn-search {           
           padding: 0;
           position: relative;
           z-index: 9;
           background-color: transparent;
           border: none;
            input {
                color: #fff;
                font-size: $font-size-base - 2px;
                padding: 0 15px;
                line-height: 52px;
                background-color: transparent;
                border: none;
            }
        }
    }
    .search-form{
        background-color: transparent;
        border: none;
        padding: 0;
        @include box-shadow(none);
        position: absolute;
        top: 0px;
        right: 5px;
        content: "";
        @include opacity(0);
        transition: all 0.35s ease 0s;
        visibility: hidden;
        display:  inline-block;
        min-width: 0;
        margin: 0;
    }
    &.open{
        .wpo-search{
            @include opacity(0);
            transition: all 0.35s ease 0s;
        }
        .search-form{
            @include opacity(1);
            transition: all 0.35s ease 0s;
            visibility: visible;
            min-width: 280px;
            .opal-search {
                width: 280px;
                transition: all 0.35s ease 0s;
                border: none;
                .input-search {
                    width: 280px;
                    transition: all 0.35s ease 0s;
                    visibility: visible;
                    @include opacity(1); 
                    @include  placeholder(#fff) ;
                }
            }
        }
    }
}

/* mc4wp-form */
.mc4wp-form-fields{
    .input-group{
        background-color: #fff;
        @include border-radius(30px);
    }
    input[type="submit"] {
        font-family: FontAwesome;
        @include border-right-radius(30px!important);
        color: $theme-color;
        padding-right: 20px;
    }
    input[type="email"] {
        @include border-left-radius(30px!important);
        box-shadow: none;
        padding-left: 20px;
    }
    
    .form-control{
        background-color: transparent;
        border: none;
    }
}

/* google map */
.kc-google-maps iframe{
    pointer-events: none;
}

/* contact-add */
.contact-add{
    i{
        color: $theme-color;
        min-width: 20px;
    }
}
.default .opal-mapping-info .mapping-view-title:before{
    content: url('#{$image-theme-skin}bg-title.png');
}
.style_2 .opal-mapping-info{
    color: #fff;
    .mapping-view-title{
        color: #fff;
        font-weight: $headings-font-weight;
        font-family: $font-family-second;
    }

}
/* wpcf7-form contact-form7 */
.wpcf7-form .contact-form7{
    .ajax-loader{
        position: absolute;
        content: "";
        margin: 0 auto;
        left: 0;
        right: 0;
     top: -10px;

    }
    .form-control{
        background-color: rgba(#000, 0.05);
        border-color: rgba(#000, 0);
        @include  placeholder(#000) ;
        color: #000;
    }
    &.contact-style1{
        background-color: $theme-color;
        padding: 40px 50px 50px!important;
        width: 390px;
        margin: 0 auto;
        h3{
            font-size: 24px!important;
            text-align: center!important;
        }
        .wpcf7-submit{
            text-align: center!important;
        }
        .form-date, .form-time{
            position: relative;
            &:before{
                position: absolute;
                top: 18px;
                right: 10px;
                font-family: FontAwesome;
                color: #000;
            }
        }
        .form-date { 
            &:before{
                content: "\f274";
            }
        }
        .form-time{
            &:before{
                content: "\f017";
            }
        }
    }
    &.newsletter {
       .btn-newsletter{
            padding-left: 20px;
            padding-right: 20px;
            background-color: rgba(0, 0, 0, 0.05);
            color: $theme-color;
            margin: 0;
            &:hover{
                color: #000;
            }
       }
    }
}
.button-header{
    position: relative;
    z-index: 1045;
    .modal-dialog .modal-content {
        background-color: transparent;
        @include box-shadow(none);
    }
}
.headeremail{
    margin-left: 15px;
    i{
        padding-right: 5px;
    }
}
.widget-opalhomes-information{
    padding: 20px 50px;
    color: #999;
    font-weight: 400;
    .widget-title{
        font-size: 24px;
    }
    .text-information{
        font-size: 18px;
        font-style: italic;
    }
    ul li {
        font-size: 16px;
        font-family: $font-family-second;
        font-weight: 700;
        strong{
            color: #333;
            font-weight: 900;
            padding-right: 5px;
        }
    }
    .car-parking{
        font-family: $font-family-second;
        span{
            display: block;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 700;
            line-height: 1;
        }
        strong{
            font-size: 24px;
            font-weight: 900;
            color: $theme-color;
        }
    }
}
.widget-absolute {
    position: absolute;
    content: "";
    top: 17%;
    width: 370px;
    background-color: #fff;
    .schedulepopup{
        a.btn{
            display: block;
            @include border-radius(0);
        }
    }
}
.box-header{
    position: relative;
}
@media screen and (max-width: $screen-xs-max) {
   .widget-absolute {
     width: 98.5%;
    }
}
@media screen and (max-width: 480px) {
   .widget-absolute {
    width: 97.5%;
    }
}
/*  */
.floor-popup{
    .floor-feature-image{
        position: relative;
        &:before{
            position: absolute;
            background-color: rgba(#fff, 0.8);
            @include size(100%, 100%);
            @include transition (all 0.3s ease-in-out 0s);
            top: 0;
            left: 0;
            content: "";
            @include opacity(0);
        }
    }
    .image-popup{
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        @include size(60px, 60px);
        background-color: $theme-color;
        @include border-radius(50%);
        line-height: 60px;
        text-align: center;
        color: #fff;
        font-size: 16px;
        @include transition (all 0.3s ease-in-out 0s);
        @include scale (0);
        @include opacity(0);
    }
	.floor-title{
		text-align: center;
		background-color: #282b38;
        text-transform: uppercase;
        color: #fff;
        line-height: 50px;
        font-size: 12px;
        font-weight: 700;
		&:hover{
			background-color: $theme-color;
		}
	}
    &:hover{
        .floor-feature-image{
            &:before{
                @include transition (all 0.3s ease-in-out 0s);
                @include opacity(1);
            }
        }
        .image-popup{
            @include transition (all 0.3s ease-in-out 0s);
            @include scale (1);
            @include opacity(1);
        }
        .floor-title{
            background-color: $theme-color;
            @include transition (all 0.3s ease-in-out 0s);
        }
    }
    .floor-popup-content{
        @include flexbox;
        padding: 40px 20px 60px;
        .floor-popup-image {
            @extend .col-md-7;
        } 
        .floor-popup-info {
            @extend .col-md-5;
            font-size: 16px;
        }
        .feature-info .feature-item {
            border-bottom: 1px solid #ebebeb;
            padding: 10px 0;

        }
        .floor-description{
            margin-bottom: 30px;
        }
    }
    h4.modal-title{
        text-transform: uppercase; 
        font-size: 18px;
        font-weight: 700;
    }
    .modal-header .close {
        margin-top: -2px;
    }
    button.close {
        background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
        border: 0 none;
        cursor: pointer;
        padding: 0;
    }
    .close {
        color: #000000;
        float: right;
        font-size: 28px;
        font-weight: 700;
        line-height: 1;
        opacity: 0.2;
        text-shadow: 0 1px 0 #ffffff;
        &:focus, &:hover {
            color: #000000;
            cursor: pointer;
            opacity: 0.5;
            text-decoration: none;
        }
    }
}
.blogcarousel-layout{
    margin-left: -15px!important;
    margin-right: -15px!important;
}
